import { Grid, Stack } from "@mui/material";
import { BoldTypography } from "../../../styles";
import MailingContractCheckbox from "../../ContractCheckbox";
import {
  BoldContractText,
  ContractSectionTitle,
  ContractTable,
  ContractTd,
  ContractText,
  ContractTh,
} from "../styles";

const DeclarationsContractSection = ({ page }: { page: 4 | 5 | 6 }) => {
  if (page === 4) {
    return (
      <>
        <Grid item xs={12}>
          <ContractSectionTitle>
            DECLARAÇÃO DE PROPÓSITOS
          </ContractSectionTitle>
        </Grid>
        <Grid item xs={12}>
          <ContractText>
            Em cumprimento ao disposto no artigo 2º, inciso III da Circular nº
            3.461/2009, do Banco Central do Brasil, o abaixo assinado declara os
            propósitos e a natureza da relação de negócios que pretende manter
            com o Banco Crefisa S/A:
          </ContractText>
          <Stack direction="row" gap="16px" marginBottom="8px">
            <MailingContractCheckbox checked label=" Conta de Depósitos à vista" />
            <MailingContractCheckbox label="Operações de Crédito" />
            <MailingContractCheckbox label="Investimentos" />
            <MailingContractCheckbox label="Câmbio" />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <ContractSectionTitle>
            DECLARAÇÕES E AUTORIZAÇÕES
             {/* - NÃO SE APLICA */}
          </ContractSectionTitle>
        </Grid>
        <Grid item xs={12}>
          <ContractText>
          Declaro para os devidos fins e efeitos de direito que: (i) sou beneficiário final efetivo de todos os valores e investimentos movimentados ou detidos por intermédio desta  conta; (ii) as informações prestadas neste formulário e em todas as declarações anexas, se for o caso, são verdadeiras, válidas e completas; e (iii) me comprometo a manter  tais informações atualizadas, nos termos da legislação em vigor. Autorizo a verificação e/ou consulta à Serasa e ao Sistema de Informações de Crédito do Banco Central  do Brasil – Bacen (SCR), bem como as informações relativas às operações realizadas no mercado de câmbio, disponibilizadas na forma do artigo 1º, inciso I da Resolução  nº 3.920/2010 do BACEN, SCPC e demais órgãos de proteção ao crédito. Declaro que conheço os termos da Lei nº 9.613/1998, com alterações introduzidas pela Lei nº 12.683/2012, que dispõe sobre os crimes de “lavagem de dinheiro”, tendo ciência de que o Banco Crefisa S/A e as instituições a ele ligadas possuem a obrigação de  comunicar ao Conselho de Controle de Atividades Financeiras (COAF) e ao BACEN a ocorrência de determinadas operações nela previstas, nada tendo a opor quanto a  este procedimento. Declaro ainda que não me oponho à eventual fornecimento deste formulário, seus anexos e informações deles constantes, bem como à comunicação  dos dados financeiros relativos à conta e aos investimentos às autoridades brasileiras ou estrangeiras, conforme exigido pela legislação aplicável no Brasil e nas jurisdições em que nasci ou sou cidadão, nacional ou residente, bem como pelos acordos internacionais firmados pelo Brasil.
          </ContractText>
          {/* <ContractText>
            1. Declaro para os devidos fins e efeitos de direito que: (i) sou
            beneficiário final efetivo de todos os valores e investimentos
            movimentados ou detidos por intermédio desta conta; (ii) as
            informações prestadas neste formulário e em todas as declarações
            anexas, se for o caso, são verdadeiras, válidas e completas; e (iii)
            me comprometo a manter tais informações atualizadas, nos termos da
            legislação em vigor.{" "}
          </ContractText>
          <ContractText>
            2. Autorizo a verificação e/ou consulta à Serasa e ao Sistema de
            Informações de Crédito do Banco Central do Brasil - Bacen (SCR), bem
            como as informações relativas às operações realizadas no mercado de
            câmbio, disponibilizadas na forma do artigo 1º, inciso da Resolução
            nº 3.930/2010 do BACEN, SCPC e demais órgãos de proteção ao crédito.{" "}
          </ContractText>
          <ContractText>
            3. Declaro que conheço os termos da Lei nº 9.613/1998, com
            alterações introduzidas pela Lei nº 12.683/2012, que dispõe sobre os
            crimes de “lavagem de dinheiro”, tendo ciência de que o Banco
            Crefisa S/A e as instituições a ele ligadas possuem a obrigação de
            comunicar ao Conselho de Controle de Atividades Financeiras (COAF) e
            ao BACEN a ocorrência de determinadas operações nela previstas, nada
            tendo a opor quanto a este procedimento.{" "}
          </ContractText>
          <ContractText>
            4. Declaro ainda que não me oponho à eventual fornecimento deste
            formulário, seus anexos e informações deles constantes, bem como à
            comunicação dos dados financeiros relativos à conta e aos
            investimentos às autoridades brasileiras ou estrangeiras, conforme
            exigido pela legislação aplicável no Brasil e na(s) jurisdição(ões)
            em que nasci ou sou cidadão(s), nacional(is) ou residente(s), bem
            como pelos acordos internacionais firmados pelo Brasil
          </ContractText> */}
        </Grid>
      </>
    );
  }

  if (page === 5) {
    return (
      <>
        <Grid item xs={12}>
          <BoldContractText textAlign="center" mt="24px" mb="8px">
            Anexo a Proposta de Abertura de Conta - Pessoa Física Pacotes de
            Tarifas
          </BoldContractText>
          <ContractText>
            <BoldTypography>Pacote Beneficiário INSS</BoldTypography>
          </ContractText>
          <ContractTable style={{ marginBlock: "6px" }}>
            <thead>
              <tr>
                <ContractTh>
                  Conta de depósito à vista
                  <br />
                  Movimentação com cartão
                </ContractTh>
                <ContractTh>
                  Serviços gratuitos por
                  <br />
                  mês (*)
                </ContractTh>
                <ContractTh>
                  Quantidade mensal
                  <br />
                  incluída no pacote
                </ContractTh>
                <ContractTh>
                  Quantidade total por
                  <br />
                  mês
                </ContractTh>
              </tr>
            </thead>
            <tbody>
              <tr>
                <ContractTd>
                  Confecção de cadastro para início de relacionamento
                </ContractTd>
                <ContractTd>-</ContractTd>
                <ContractTd>-</ContractTd>
                <ContractTd>-</ContractTd>
              </tr>
              <tr>
                <ContractTd>Saque</ContractTd>
                <ContractTd>4</ContractTd>
                <ContractTd>-</ContractTd>
                <ContractTd>4</ContractTd>
              </tr>
              <tr>
                <ContractTd>Extrato dos últimos 30 dias</ContractTd>
                <ContractTd>2</ContractTd>
                <ContractTd>Ilimitado</ContractTd>
                <ContractTd>Ilimitado</ContractTd>
              </tr>
              <tr>
                <ContractTd>Extrato de outros períodos</ContractTd>
                <ContractTd>-</ContractTd>
                <ContractTd>Ilimitado</ContractTd>
                <ContractTd>Ilimitado</ContractTd>
              </tr>
              <tr>
                <ContractTd>Transferência por meio de DOC</ContractTd>
                <ContractTd rowSpan={2}>-</ContractTd>
                <ContractTd rowSpan={2}>2</ContractTd>
                <ContractTd rowSpan={2}>2</ContractTd>
              </tr>
              <tr>
                <ContractTd>Transferência por meio de TED</ContractTd>
              </tr>
              <tr>
                <ContractTd>
                  Transferência entre contas na própria instituição
                </ContractTd>
                <ContractTd>2</ContractTd>
                <ContractTd>Ilimitado</ContractTd>
                <ContractTd>Ilimitado</ContractTd>
              </tr>
            </tbody>
          </ContractTable>
          <ContractText>
            (*) Gratuidades estabelecidas pelo art. 2º da Resolução 3.919, de 25
            de novembro de 2010.
          </ContractText>
          <br />
          <ContractText>
            <BoldTypography>
              Pacote Padronizado de Serviços Prioritários I
            </BoldTypography>
          </ContractText>
          <ContractTable style={{ marginBlock: "6px" }}>
            <thead>
              <tr>
                <ContractTh>
                  Conta de depósito à vista
                  <br />
                  Movimentação com cartão
                </ContractTh>
                <ContractTh>
                  Serviços gratuitos por
                  <br />
                  mês (*)
                </ContractTh>
                <ContractTh>
                  Quantidade mensal
                  <br />
                  incluída no pacote
                </ContractTh>
                <ContractTh>
                  Quantidade total por
                  <br />
                  mês
                </ContractTh>
              </tr>
            </thead>
            <tbody>
              <tr>
                <ContractTd>
                  Confecção de cadastro para início de relacionamento
                </ContractTd>
                <ContractTd>-</ContractTd>
                <ContractTd>-</ContractTd>
                <ContractTd>-</ContractTd>
              </tr>
              <tr>
                <ContractTd>Saque</ContractTd>
                <ContractTd>4</ContractTd>
                <ContractTd>4</ContractTd>
                <ContractTd>8</ContractTd>
              </tr>
              <tr>
                <ContractTd>Extrato dos últimos 30 dias</ContractTd>
                <ContractTd>2</ContractTd>
                <ContractTd>2</ContractTd>
                <ContractTd>4</ContractTd>
              </tr>
              <tr>
                <ContractTd>Extrato de outros períodos</ContractTd>
                <ContractTd>-</ContractTd>
                <ContractTd>2</ContractTd>
                <ContractTd>2</ContractTd>
              </tr>
              <tr>
                <ContractTd>
                  Transferência entre contas na própria instituição
                </ContractTd>
                <ContractTd>2</ContractTd>
                <ContractTd>2</ContractTd>
                <ContractTd>4</ContractTd>
              </tr>
            </tbody>
          </ContractTable>
          <ContractText>
            (*) Gratuidades estabelecidas pelo art. 2º da Resolução 3.919, de 25
            de novembro de 2010.
          </ContractText>
          <br />
          
        </Grid>
      </>
    );
  }

  if (page === 6) {
    return (
      <>
        <Grid item xs={12}>
        <ContractText>
            <BoldTypography>
              Pacote Padronizado de Serviços Prioritários II
            </BoldTypography>
          </ContractText>
          <ContractTable style={{ marginBlock: "6px" }}>
            <thead>
              <tr>
                <ContractTh>
                  Conta de depósito à vista
                  <br />
                  Movimentação com cartão
                </ContractTh>
                <ContractTh>
                  Serviços gratuitos por
                  <br />
                  mês (*)
                </ContractTh>
                <ContractTh>
                  Quantidade mensal
                  <br />
                  incluída no pacote
                </ContractTh>
                <ContractTh>
                  Quantidade total por
                  <br />
                  mês
                </ContractTh>
              </tr>
            </thead>
            <tbody>
              <tr>
                <ContractTd>
                  Confecção de cadastro para início de relacionamento
                </ContractTd>
                <ContractTd>-</ContractTd>
                <ContractTd>-</ContractTd>
                <ContractTd>-</ContractTd>
              </tr>
              <tr>
                <ContractTd>Saque</ContractTd>
                <ContractTd>4</ContractTd>
                <ContractTd>4</ContractTd>
                <ContractTd>8</ContractTd>
              </tr>
              <tr>
                <ContractTd>Extrato dos últimos 30 dias</ContractTd>
                <ContractTd>2</ContractTd>
                <ContractTd>4</ContractTd>
                <ContractTd>6</ContractTd>
              </tr>
              <tr>
                <ContractTd>Extrato de outros períodos</ContractTd>
                <ContractTd>-</ContractTd>
                <ContractTd>2</ContractTd>
                <ContractTd>2</ContractTd>
              </tr>
              <tr>
                <ContractTd>Transferência por meio de DOC</ContractTd>
                <ContractTd rowSpan={2}>-</ContractTd>
                <ContractTd rowSpan={2}>1</ContractTd>
                <ContractTd rowSpan={2}>1</ContractTd>
              </tr>
              <tr>
                <ContractTd>Transferência por meio de TED</ContractTd>
              </tr>
              <tr>
                <ContractTd>
                  Transferência entre contas na própria instituição
                </ContractTd>
                <ContractTd>2</ContractTd>
                <ContractTd>2</ContractTd>
                <ContractTd>4</ContractTd>
              </tr>
            </tbody>
          </ContractTable>
          <ContractText>
            (*) Gratuidades estabelecidas pelo art. 2º da Resolução 3.919, de 25
            de novembro de 2010.
          </ContractText>
          <br />
          <ContractText>
            <BoldTypography>
              Pacote Padronizado de Serviços Prioritários III
            </BoldTypography>
          </ContractText>
          <ContractTable style={{ marginBlock: "6px" }}>
            <thead>
              <tr>
                <ContractTh>
                  Conta de depósito à vista
                  <br />
                  Movimentação com cartão
                </ContractTh>
                <ContractTh>
                  Serviços gratuitos por
                  <br />
                  mês (*)
                </ContractTh>
                <ContractTh>
                  Quantidade mensal
                  <br />
                  incluída no pacote
                </ContractTh>
                <ContractTh>
                  Quantidade total por
                  <br />
                  mês
                </ContractTh>
              </tr>
            </thead>
            <tbody>
              <tr>
                <ContractTd>
                  Confecção de cadastro para início de relacionamento
                </ContractTd>
                <ContractTd>-</ContractTd>
                <ContractTd>-</ContractTd>
                <ContractTd>-</ContractTd>
              </tr>
              <tr>
                <ContractTd>Saque</ContractTd>
                <ContractTd>4</ContractTd>
                <ContractTd>4</ContractTd>
                <ContractTd>8</ContractTd>
              </tr>
              <tr>
                <ContractTd>Extrato dos últimos 30 dias</ContractTd>
                <ContractTd>2</ContractTd>
                <ContractTd>4</ContractTd>
                <ContractTd>6</ContractTd>
              </tr>
              <tr>
                <ContractTd>Extrato de outros períodos</ContractTd>
                <ContractTd>-</ContractTd>
                <ContractTd>2</ContractTd>
                <ContractTd>2</ContractTd>
              </tr>
              <tr>
                <ContractTd>Transferência por meio de DOC</ContractTd>
                <ContractTd rowSpan={2}>-</ContractTd>
                <ContractTd rowSpan={2}>1</ContractTd>
                <ContractTd rowSpan={2}>1</ContractTd>
              </tr>
              <tr>
                <ContractTd>Transferência por meio de TED</ContractTd>
              </tr>
              <tr>
                <ContractTd>
                  Transferência entre contas na própria instituição
                </ContractTd>
                <ContractTd>2</ContractTd>
                <ContractTd>2</ContractTd>
                <ContractTd>4</ContractTd>
              </tr>
            </tbody>
          </ContractTable>
          <ContractText>
            (*) Gratuidades estabelecidas pelo art. 2º da Resolução 3.919, de 25
            de novembro de 2010.
          </ContractText>
          <ContractText>
            <BoldTypography>
              Pacote Padronizado de Serviços Prioritários IV
            </BoldTypography>
          </ContractText>
          <ContractTable style={{ marginBlock: "6px" }}>
            <thead>
              <tr>
                <ContractTh>
                  Conta de depósito à vista
                  <br />
                  Movimentação com cartão
                </ContractTh>
                <ContractTh>
                  Serviços gratuitos por
                  <br />
                  mês (*)
                </ContractTh>
                <ContractTh>
                  Quantidade mensal
                  <br />
                  incluída no pacote
                </ContractTh>
                <ContractTh>
                  Quantidade total por
                  <br />
                  mês
                </ContractTh>
              </tr>
            </thead>
            <tbody>
              <tr>
                <ContractTd>
                  Confecção de cadastro para início de relacionamento
                </ContractTd>
                <ContractTd>-</ContractTd>
                <ContractTd>-</ContractTd>
                <ContractTd>-</ContractTd>
              </tr>
              <tr>
                <ContractTd>Saque</ContractTd>
                <ContractTd>4</ContractTd>
                <ContractTd>8</ContractTd>
                <ContractTd>12</ContractTd>
              </tr>
              <tr>
                <ContractTd>Extrato dos últimos 30 dias</ContractTd>
                <ContractTd>2</ContractTd>
                <ContractTd>6</ContractTd>
                <ContractTd>8</ContractTd>
              </tr>
              <tr>
                <ContractTd>Extrato de outros períodos</ContractTd>
                <ContractTd>-</ContractTd>
                <ContractTd>4</ContractTd>
                <ContractTd>4</ContractTd>
              </tr>
              <tr>
                <ContractTd>Transferência por meio de DOC</ContractTd>
                <ContractTd rowSpan={2}>-</ContractTd>
                <ContractTd rowSpan={2}>3</ContractTd>
                <ContractTd rowSpan={2}>3</ContractTd>
              </tr>
              <tr>
                <ContractTd>Transferência por meio de TED</ContractTd>
              </tr>
              <tr>
                <ContractTd>
                  Transferência entre contas na própria instituição
                </ContractTd>
                <ContractTd>2</ContractTd>
                <ContractTd>6</ContractTd>
                <ContractTd>8</ContractTd>
              </tr>
            </tbody>
          </ContractTable>
          <ContractText>
            (*) Gratuidades estabelecidas pelo art. 2º da Resolução 3.919, de 25
            de novembro de 2010.
          </ContractText>
          {/* <BoldContractText textAlign="center" marginTop="40px">
            CONDIÇÕES GERAIS PARA ABERTURA, MANUTENÇÃO, MOVIMENTAÇÃO E
            ENCERRAMENTO DE
            <br />
            CONTA-CORRENTE - PESSOA FÍSICA E PESSOA JURÍDICA
          </BoldContractText>
          <br />
          <ContractText>
            O presente Contrato de Abertura, Manutenção, Movimentação e
            Encerramento de Conta-Corrente - Pessoa Física e Pessoa Jurídica
            (“Contrato”), juntamente com a Ficha Cadastral de Pessoa Física ou
            de Pessoa Jurídica (“Ficha Cadastral”) e a Proposta de Abertura de
            Conta de Pessoa Física ou de Pessoa Jurídica (“PAC”), tem a
            finalidade de disciplinar a relação jurídica entre o BANCO CREFISA
            S.A., instituição financeira autorizada a funcionar pelo Banco
            Central do Brasil, com sede na Rua Canadá, 390, São Paulo - SP,
            inscrita no CNPJ/MF sob o n.º 61.033.106/0001-86, neste ato
            representada na forma de seu Estatuto Social (“BANCO CREFISA”), e o
            cliente qualificado na Ficha Cadastral e na PAC (coletivamente,
            “Clientes”) para a abertura, manutenção, movimentação e encerramento
            de conta-corrente - Pessoa Física e Pessoa Jurídica.
          </ContractText>
          <br />
          <ContractText>
            <BoldTypography>1. OBJETO DO CONTRATO </BoldTypography>
          </ContractText>
          <ContractText>
            <BoldTypography>1.1 </BoldTypography>
            Este Contrato rege a abertura, manutenção, movimentação e
            encerramento de conta-corrente bancária, a qual registrará créditos
            e débitos entre o BANCO CREFISA e o Cliente , que sejam exigíveis à
            vista, bem como as operações bancárias, todas vinculadas à
            conta-corrente, de depósito à vista, ordens de pagamento, cartões
            magnéticos, transações por meios eletrônicos e tantas outras que
            forem disponibilizadas pelo BANCO CREFISA aos Clientes.
          </ContractText>
          <ContractText>
            <BoldTypography> 1.2 </BoldTypography>
            Não se submetem a este Contrato as operações que não se vinculam à
            conta-corrente, bem como créditos e débitos relativos a depósitos em
            poupança, depósitos destinados a investimento, depósitos a prazo e
            todos aqueles que não sejam exigíveis à vista.
          </ContractText>
          <br />
          <ContractText>
            <BoldTypography> 2. ADESÃO AO CONTRATO </BoldTypography>
          </ContractText>
          <ContractText>
            <BoldTypography>2.1 </BoldTypography>A adesão a este Contrato será
            realizada por quaisquer dos meios admitidos em direito, em especial
            por meio da aceitação, pelo BANCO CREFISA, da PAC preenchida e
            assinada pelo Cliente.{" "}
          </ContractText>
          <ContractText>
            <BoldTypography>2.2 </BoldTypography>
            Uma vez aceita a PAC pelo BANCO CREFISA, o Cliente receberá uma via
            deste Contrato, em versão impressa ou em meio eletrônico. O referido
            procedimento será adotado também quando da celebração de eventuais
            aditamentos ao Contrato, sendo certo que a referência ao Contrato
            presumirá a referência a todos os seus aditamentos, ainda que não
            especificamente mencionados.
          </ContractText>
          <br />
          <ContractText>
            <BoldTypography>
              3. INFORMAÇÕES E DOCUMENTOS CADASTRAIS{" "}
            </BoldTypography>
          </ContractText>
          <ContractText>
            <BoldTypography>3.1 </BoldTypography>O Cliente deverá comunicar
            imediatamente ao BANCO CREFISA toda e qualquer alteração das
            informações cadastrais, especialmente, dados de renda, patrimônio,
            estado civil, telefone, endereço comercial, residencial e eletrônico
            por eles prestadas na FIcha Cadastral e na PAC, bem como nos
            documentos por eles apresentados.
          </ContractText>
          <ContractText>
            <BoldTypography>3.2 </BoldTypography>Se não houver comunicação de
            qualquer mudança nas informações cadastrais do Cliente, para todos
            os efeitos, os avisos e as cartas enviados para o último endereço
            registrado, que tenha sido informado ao BANCO CREFISA, serão
            considerados como recebidos.
          </ContractText>
          <br />
          <ContractText>
            <BoldTypography>4. ABERTURA DA CONTA-CORRENTE </BoldTypography>
          </ContractText>
          <ContractText>
            <BoldTypography>4.1 </BoldTypography>
            Com a adesão a este Contrato, o BANCO CREFISA abrirá e manterá em
            seus sistemas a conta-corrente bancária em nome dos Clientes,
            utilizando-se, para tanto, dos dados cadastrais constantes na Ficha
            Cadastral e na PAC, de acordo com os documentos entregues pelos
            Clientes e conforme exigido pela regulamentação aplicável a contas
            de depósito à vista. A conta-corrente será escriturada na agência do
            BANCO CREFISA indicada na PAC.{" "}
          </ContractText>
          <br />
          <ContractText>
            <BoldTypography> 5. MOVIMENTAÇÃO DA CONTA-CORRENTE </BoldTypography>
          </ContractText>
          <ContractText>
            <BoldTypography>5.1 </BoldTypography>O Cliente movimentará a
            conta-corrente aberta nos termos deste Contrato, de forma a permitir
            ao BANCO CREFISA realizar lançamentos a crédito e a débito.
          </ContractText>
          <ContractText>
            <BoldTypography>5.2 </BoldTypography>
            Na conta-corrente, serão lançados a crédito todos os valores cujo
            pagamento poderá ser reclamado pelo Cliente, tais como: (i)
            montantes relativos a depósito à vista (que poderá ser feito por
            meio de depósito via boleto); (ii) valores pagos ao BANCO CREFISA
            por terceiros e destinados aos Clientes, tais como ordens de
            pagamento, transferências de recursos enviadas em benefício do
            Cliente, inclusive Transferências Eletrônicas Disponíveis (TED) e
            Documentos de Ordem de Crédito (DOC); (iii) sacados contra outras
            instituições financeiras, depositados na conta-corrente dos Clientes
            no BANCO CREFISA quando o depósito poderá ser realizado em um ponto
            de atendimento ou por meio de aplicativo, conforme regras
            estabelecidas no anexo; (iv) valores devidos pelo BANCO CREFISA ou
            pela Crefisa S/A Crédito, Financiamento e Investimentos, ao Cliente,
            com base em qualquer relação jurídica entre as partes mantida, tais
            como empréstimos concedidos, exceto no caso de previsão expressa em
            contrário no respectivo Contrato.
          </ContractText>
          <ContractText>
            <BoldTypography>5.3 </BoldTypography>
            Na conta-corrente, serão lançados a débito todos os valores cujo
            pagamento o BANCO CREFISA poderá reclamar com os Clientes,
            incluindo, mas não se limitando a: (i) valores devidos pelo Cliente
            ao BANCO CREFISA e a Crefisa S/A Crédito, Financiamento e
            Investimentos, com base em qualquer relação jurídica entre as partes
            mantida, tais como empréstimos e serviços contratados e tarifas por
            serviços prestados; (ii) ordens de pagamento sacadas pelo Cliente
            contra o BANCO CREFISA, inclusive por meio de Documentos de Ordem de
            Crédito (DOC) e Transferências Eletrônicas Disponíveis (TED); (iii)
            pagamentos efetuados pelo BANCO CREFISA em favor do Cliente;(iv)
            valores devidos pelos Clientes com base na utilização do cartão com
            função débito.
          </ContractText>
          <ContractText>
            <BoldTypography>5.4 </BoldTypography>
            As relações contratuais que derem causa a créditos e débitos na
            conta-corrente do Cliente serão regidas pelos respectivos termos
            contratuais, os quais não se confundirão com os termos e as
            condições deste Contrato, que a eles será acessório. O lançamento na
            conta-corrente não alterará a natureza jurídica dos créditos e
            débitos, seus acessórios, tampouco suspenderá sua exigibilidade.
          </ContractText>
          <ContractText>
            <BoldTypography>5.5 </BoldTypography>O BANCO CREFISA permitirá a
            movimentação da conta-corrente por meio de instruções escritas e
            assinadas pelo Cliente ou por meio de senhas, no caso de
            movimentação envolvendo meios eletrônicos, como: Internet Banking,
            cartão com função débito, aplicativos, entre outros. O acesso à
            conta por meio eletrônico dar-se-á mediante a utilização de senhas,
            cadastradas no BANCO CREFISA esclusivamente pelo Cliente, sendo
            estas pessoais e intransferíveis. O BANCO CREFISA poderá, a qualquer
            tempo, independentemente de qualquer aviso, alterar as condições de
            uso da senha do CLIENTE e/ou cancelar a sua validade e/ou efetuar
            seu bloqueio, sendo que a partir da data de comunicação do
            cancelamento/bloqueio da mesma o CLIENTE não mais poderá utilizar os
            serviços ora avençados.
          </ContractText>
          <ContractText>
            <BoldTypography>5.5.1 </BoldTypography>O Cliente autoriza o BANCO
            CREFISA, por prazo indeterminado, a realizar estornos de lançamentos
            incorretos efetuados na sua conta-corrente decorrentes de falha
            operacional. Os Clientes que identificarem inconsistências de
            lançamentos na sua conta-corrente deverão informar, imediatamente o
            BANCO CREFISA para regularização.
          </ContractText>
          <ContractText>
            <BoldTypography>5.5.2 </BoldTypography> O BANCO CREFISA não será
            responsabilizado se o Cliente não conseguir movimentar a conta em
            razão de bloqueio por ordem administrativa ou judicial. 5.5.3
            Qualquer alteração em relação aos procuradores ou representantes
            legais autorizados pelo Cliente para movimentar a conta-corrente
            deverá ser comunicada imediatamente e por escrito, ficando o BANCO
            CREFISA isento de toda e qualquer responsabilidade por eventuais
            prejuízos que venham a ocorrer em virtude da não formalização.
          </ContractText>
          <ContractText>
            <BoldTypography>5.6 </BoldTypography>O prazo para pagamento e
            liquidação das ordens de pagamento cursadas pelo BANCO CREFISA no
            interesse dos Clientes seguirão os prazos estabelecidos pelas
            respectivas câmaras de compensação.
          </ContractText> */}
        </Grid>
      </>
    );
  }

  // if (page === 7) {
  //   return (
  //     <Grid item xs={12}>
  //       <ContractText>
  //         <BoldTypography>5.7 </BoldTypography>O BANCO CREFISA poderá cobrar por
  //         serviços especiais ou diferenciados prestados aos Clientes. As tarifas
  //         indicadas constam na Tabela de Tarifas, disponibilizada no site
  //         www.crefisa.com.br (“Tabela de Tarifas”), além das demais tarifas
  //         previstas na legislação aplicável.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>5.8 </BoldTypography>
  //         Os Clientes obrigam-se a não emitir ordens de pagamentos, ou se
  //         utilizarem de quaisquer instrumentos de pagamento e/ou movimentação
  //         bancária contra o BANCO CREFISA sem dispor em sua conta-corrente de
  //         saldo credor suficiente, sob pena de aplicação das respectivas
  //         penalidades. Para fins do aqui exposto, integrará o saldo credor da
  //         conta-corrente o limite de quaisquer aberturas de crédito em
  //         conta-corrente contratadas pelos Clientes no BANCO CREFISA.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>5.8.1 </BoldTypography>
  //         No caso de devolução de ordem de pagamento, ou transferência bancária
  //         por meio eletrônico ou por cartão, emitida sem a devida provisão de
  //         fundos em conta-corrente, os Clientes deverão ressarcir o BANCO
  //         CREFISA dos custos incorridos com o seu processamento e rejeição do
  //         pagamento, além das tarifas aplicáveis.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>5.8.2 </BoldTypography>O Cliente deverá cobrir
  //         qualquer saldo a descoberto verificado em conta-corrente, no mesmo dia
  //         de sua verificação, sob pena de incorrer em mora, passando a incidir o
  //         previsto na Cláusula 8 - Mora e Encargos Moratórios, deste Contrato. O
  //         Cliente, desde já, autoriza o BANCO CREFISA, se necessário e a seu
  //         critério, resgatar qualquer aplicação financeira vinculada à
  //         conta-corrente a fim de liquidar o saldo a descoberto eventualmente
  //         existente.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>5.8.3 </BoldTypography>
  //         Qualquer ordem de pagamento, ou transferência bancária por meio
  //         eletrônico ou por cartão emitida em valor superior ao saldo disponível
  //         em conta-corrente será rejeitada pelo BANCO CREFISA ou poderá ser pelo
  //         BANCO CREFISA recusada, caracterizando-se uma operação a descoberto,
  //         nos termos previstos na Cláusula 6 deste Contrato.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>5.9 </BoldTypography>
  //         Os depósitos por boletos devem respeitar os valores mínimos e máximos
  //         definidos para estes produtos, conforme tabela disponibilizada pela
  //         Crefisa em seu site.
  //       </ContractText>
  //       <br />
  //       <ContractText>
  //         <BoldTypography>6. ADIANTAMENTO A DEPOSITANTE </BoldTypography>
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>6.1 </BoldTypography>O Cliente pode solicitar que o
  //         BANCO CREFISA analise e avalie, em caráter emergencial, a
  //         possibilidade de concessão de Adiantamento a Depositante para suportar
  //         débitos que ultrapassem o saldo disponível em sua conta-corrente.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>6.2 </BoldTypography>Sobre o Adiantamento a
  //         Depositante utilizado incidirão, diariamente, desde a data da
  //         concessão do adiantamento até a sua recomposição, juros à taxa
  //         publicada pelo BANCO CREFISA nos canais de relacionamento. Os juros
  //         serão debitados juntamente com o Imposto sobre Operações Financeiras
  //         (IOF) correspondente, diretamente da conta-corrente, no primeiro dia
  //         útil de cada mês.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>6.3 </BoldTypography>O serviço de Adiantamento a
  //         Depositante é tarifado, conforme Tabela de Tarifas em vigor. 6.4 Serão
  //         cobradas do Cliente as despesas com cobrança judicial e extrajudicial
  //         e os custos administrativos relacionados. 6.5 O limite emergencial
  //         está condicionado à avaliação pelo BANCO CREFISA a cada ocorrência.
  //         6.6 Os clientes poderão, a qualquer momento, solicitar ao BANCO
  //         CREFISA o cancelamento desse serviço.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>6.4 </BoldTypography>Serão cobradas do Cliente as
  //         despesas com cobrança judicial e extrajudicial e os custos
  //         administrativos relacionados.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>6.5 </BoldTypography>O limite emergencial está
  //         condicionado à avaliação pelo BANCO CREFISA a cada ocorrência.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>6.6 </BoldTypography>Os clientes poderão, a qualquer
  //         momento, solicitar ao BANCO CREFISA o cancelamento desse serviço.
  //       </ContractText>
  //       <br />
  //       <ContractText>
  //         <BoldTypography>7. EXTRATO </BoldTypography>
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>7.1 </BoldTypography>
  //         Toda movimentação da conta-corrente será registrada em extratos, que
  //         serão disponibilizados via canais eletrônicos.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>7.2 </BoldTypography>O fornecimento via correio de
  //         extratos mensais consolidados impressos, bem como de informações de
  //         outros produtos e serviços vinculados à conta-corrente dos Clientes
  //         podem ser tarifados, conforme Tabela de Tarifas publicada no site
  //         www.crefisa.com.br. A realização de consultas de saldo e extrato pela
  //         internet é insenta de qualquer tipo de tarifa.
  //       </ContractText>
  //       <br />
  //       <ContractText>
  //         <BoldTypography>8. MORA E ENCARGOS MORATÓRIOS </BoldTypography>
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>8.1 </BoldTypography>
  //         Caso o Cliente incorra em mora em relação a qualquer obrigação de
  //         pagamento de valores decorrentes deste Contrato, sobre os valores em
  //         mora, incidirão juros remuneratórios em conformidade à taxa prevista
  //         na respectiva operação inadimplida, juros moratórios, de forma
  //         capitalizada, de 12% (doze por cento) ao ano, bem como multa de 2%
  //         (dois por cento), calculados desde a data do vencimento até a data do
  //         efetivo pagamento. Em caso de cobrança extrajudicial ou judicial,
  //         serão devidas despesas de cobrança e honorários advocatícios.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>8.2 </BoldTypography>O Cliente, desde já, autorizam,
  //         de forma irrevogável e irretratável, o BANCO CREFISA a debitar, de
  //         qualquer conta-corrente que sejam titulares, os montantes
  //         correspondentes às obrigações em mora decorrentes deste Contrato e de
  //         contratos celebrados com a Crefisa S/A Crédito, Financiamento e
  //         Investimentos, acrescidos dos respectivos juros e encargos moratórios
  //         pactuados nos contratos.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>8.3 </BoldTypography>A mora caracterizar-se-á pelo
  //         descumprimento, total ou parcial, de qualquer obrigação decorrente
  //         deste Contrato, independentemente de qualquer notificação ou
  //         interpelação, judicial ou extrajudicial.
  //       </ContractText>
  //       <br />
  //       <ContractText>
  //         <BoldTypography>
  //           9. REMUNERAÇÃO DEVIDA AO BANCO CREFISA
  //         </BoldTypography>
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>9.1 </BoldTypography>O Cliente poderá indicar na
  //         Proposta de Abertura de Conta, ou a qualquer momento após a abertura
  //         da conta-corrente, qiando aplicável, a opção por contratar um dos
  //         pacotes de serviços bancários do BANCO CREFISA.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>9.1.1 </BoldTypography>
  //         Os serviços não inclusos no pacote de serviços contratados serão
  //         cobrados separadamente, conforme previsto na Cláusula 5.3 do presente
  //         Contrato.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>9.1.2 </BoldTypography>A contratação de pacote de
  //         serviços, quando aplicável, não é obrigatória. Ao optar pela não
  //         contratação, o Cliente poderá utilizar gratuitamente apenas os
  //         serviços essenciais, dispostos na PAC.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>9.1.3 </BoldTypography>O valor das tarifas e dos
  //         Pacotes de Serviços constam na Tabela de Tarifas, que é parte
  //         integrante deste Contrato, e está disponível no site
  //         www.crefisa.com.br
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>9.1.4 </BoldTypography>A Tabela de Tarifas poderá ser
  //         alterada a critério do BANCO CREFISA, passando a vigorar, após o prazo
  //         fixado pelo BANCO CENTRAL DO BRASIL.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>9.2 </BoldTypography>O Cliente autoriza a cobrança de
  //         remuneração correspondente aos serviços vinculados à conta-corrente no
  //         momento da respctiva utilização, no valor estipulado pelo BANCO
  //         CREFISA e constante da respectiva Tabela de Tarifas.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>9.3 </BoldTypography>O BANCO CREFISA poderá lançar a
  //         débito na conta-corrente, até quando o saldo disponível suportar, as
  //         tarifas bancárias relacionadas a serviços vinculados à conta-corrente.
  //         Poderão ainda ser lançadas em conta-corrente tarifas bancárias
  //         decorrentes de outras relações jurídicas mantidas entre o Cliente e o
  //         BANCO CREFISA, desde que, no momento da contratação, tal possibilidade
  //         seja informada aos Clientes e por eles autorizada no respectivo
  //         contrato.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>9.4 </BoldTypography>Caso o saldo disponível em
  //         conta-corrente não suporte o débito das tarifas devidas, o BANCO
  //         CREFISA provisionará os valores das tarifas até a respectiva
  //         prescrição de sua pretensão de cobrança e os debitará assim que haja
  //         disponibilidade de saldo. No tempo que incorrer entre a data na qual a
  //         tarifa se tornou devida e seu efetivo débito, os valores em aberto
  //         ficarão sujeitos aos encargos moratórios mencionados na Cláusula 8 -
  //         Mora e Encargos Moratórios deste Contrato.
  //       </ContractText>
  //       <br />
  //       <ContractText>
  //         <BoldTypography> 10. PRAZO CONTRATUAL </BoldTypography>
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>10.1 </BoldTypography>
  //         Este Contrato vigorará por prazo indeterminado, podendo ser rescindido
  //         a qualquer momento pelo BANCO CREFISA ou pelos Clientes, mediante
  //         notificação com antecedência de 10 (dez) dias úteis.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>10.2 </BoldTypography>
  //         Qualquer uma das partes poderá, ainda, considerar rescindido este
  //         Contrato, independentemente de notificação prévia, na hipótese de
  //         descumprimento de quaisquer obrigações nele previstas, sujeitando-se
  //         às condições previstas na Cláusula 10.5 do presente Contrato.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>10.3 </BoldTypography>O BANCO CREFISA poderá, ainda,
  //         de pleno direito, encerrar a conta-corrente dos Clientes se: (i)
  //         houver irregularidades nas informações prestadas pelo Cliente na Ficha
  //         Cadastral, na PAC e/ou em qualquer outro instrumento que venha a ser
  //         formalizado com o BANCO CREFISA, julgadas de natureza grave,
  //         comunicando o fato ao BANCO CENTRAL DO BRASIL; (ii) os Clientes, por
  //         qualquer motivo, figurarem ou venham a figurar no CCF; (iii) deixar
  //         sem movimentação a conta-corrente por mais de 3 (três) meses
  //         subsequentes e ininterruptos; e (iv) houver o descumprimento de
  //         quaisquer obrigações previstas neste Contrato, ou na legislação
  //         aplicável.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>10.4 </BoldTypography>O Cliente poderá, por sua
  //         iniciativa, solicitar a rescisão deste Contrato, com encerramento da
  //         conta-corrente, preenchendo o formulário para tanto disponibilizado
  //         pelo BANCO CREFISA. Neste caso, o BANCO CREFISA processará o
  //         respectivo pedido no prazo de até 30 (trinta) dias.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>10.5 </BoldTypography>
  //         Constituem obrigações para o encerramento da conta-corrente pelo
  //         Cliente: (i) liquidarem eventual saldo devedor em conta-corrente no
  //         prazo de 30 (trinta) dias a contar da solicitação de encerramento ;
  //         (ii) promoverem a entrega de recursos necessários para prover os
  //         débitos em conta-corrente já programados; (iii) inexistência de
  //         bloqueio judicial com relação ao saldo vinculado à conta-corrente a
  //         ser encerrada; (iv) estar a conta-corrente desvinculada de qualquer
  //         Contrato ou aplicação com o BANCO CREFISA;
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>10.6 </BoldTypography>O não cumprimento de qualquer
  //         uma das condições acima estipuladas redundará no cancelamento do
  //         pedido de encerramento da conta-corrente, podendo o BANCO CREFISA
  //         cobrar as tarifas incidentes como se o pedido de encerramento da
  //         conta-corrente nunca tivesse sido apresentado.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>10.7 </BoldTypography>
  //         Durante o processamento do encerramento da conta-corrente, o BANCO
  //         CREFISA processará débitos e créditos na conta-corrente que sejam
  //         apresentados e cancelará todos os pagamentos cadastrados como débito
  //         automático.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>10.8 </BoldTypography>O Cliente, desde já, anui que a
  //         não movimentação da conta-corrente não ocasionará o seu encerramento
  //         automático, podendo o BANCO CREFISA cobrar as devidas tarifas pelos
  //         serviços incidentes durante a sua manutenção.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>10.9 </BoldTypography>
  //         Rescindido este Contrato e encerrada a conta-corrente, os Clientes não
  //         poderão realizar as operações bancárias previstas neste Contrato. Se,
  //         na data de seu encerramento, houver saldo credor na conta-corrente,
  //         este ficará à disposição dos Clientes, sem curso de juros ou
  //         atualização monetária.
  //       </ContractText>
  //     </Grid>
  //   );
  // }

  // if (page === 8) {
  //   return (
  //     <Grid item xs={12}>
  //       <ContractText>
  //         <BoldTypography>11. ALTERAÇÃO CONTRATUAL </BoldTypography>
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>11.1 </BoldTypography>
  //         Os termos deste Contrato poderão ser alterados pelo BANCO CREFISA a
  //         qualquer momento, conforme exigirem as condições aplicáveis a seus
  //         negócios bancários, à legislação ou à regulamentação aplicável. Tais
  //         alterações devem ser prévia e expressamente comunicadas aos Clientes.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>11.2 </BoldTypography>
  //         Todas e quaisquer alterações às disposições deste Contrato serão
  //         comunicadas aos Clientes, ficando facultada a estes a rescisão deste
  //         Contrato, de acordo com o procedimento previsto neste Contrato.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>12. CARTÃO </BoldTypography>
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>12.1 </BoldTypography>O Cartão com função débito da
  //         conta, destinado à realização de operações nos terminais eletrônicos
  //         de autoatendimento e realização de compras na função débito, é para
  //         uso mediante senha pessoal e intransferível dos Clientes, que se
  //         responsabilizarão pela sua guarda, integridade e segurança.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>12.2 </BoldTypography>O BANCO CREFISA não se
  //         responsabilizará pela utilização do cartão por terceiros.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>12.3 </BoldTypography>O CLIENTE deverá manter sua
  //         senha em absoluto sigilo e com toda a segurança, nunca anotada junto
  //         ao cartão, sendo responsáveis por todas as consequências decorrentes
  //         do seu uso indevido por terceiros.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>12.4 </BoldTypography>O CLIENTE autoriza o BANCO
  //         CREFISA a enviar o cartão com função débito, devidamente bloqueado,
  //         para o endereço cadastrado do titular. O cartão poderá ser entregue a
  //         qualquer pessoa, ou colocado na caixa de correio no endereço indicado,
  //         cabendo ao CLIENTE solicitarem ao BANCO CREFISA o desbloqueio, pelos
  //         meios disponibilizados para tal.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>12.5 </BoldTypography>A qualquer tempo o CLIENTE
  //         poderá modificar sua senha, bem como deverão solicitar o bloqueio do
  //         uso do cartão, em caso de extravio, furto ou roubo, mediante
  //         comunicação imediata ao BANCO CREFISA. Não havendo essa comunicação
  //         tempestivamente, qualquer prejuízo decorrente será de inteira
  //         responsabilidade do CLIENTE.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>12.6 </BoldTypography>
  //         No caso de rescisão, o CLIENTE obriga a destruir o cartão em seu
  //         poder.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>12.7 </BoldTypography>
  //         Caso o BANCO CREFISA tenha conhecimento de indícios e/ou suspeitas de
  //         uso indevido da conta, por meio do cartão, internet banking ou
  //         telefonia, o BANCO CREFISA poderá bloquear o uso do cartão e/ou senha
  //         de movimentação, independentemente de comunicação prévia, até que
  //         sejam concluídas as averiguações, podendo, a seu exclusivo critério,
  //         solicitar ao CLIENTE a troca de senha de movimentação e/ou a
  //         substituição do cartão.
  //       </ContractText>
  //       <br />
  //       <ContractText>
  //         <BoldTypography>13. DISPOSIÇÕES GERAIS </BoldTypography>
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>13.1 </BoldTypography>O Cliente concorda e autoriza
  //         que o BANCO CREFISA proceda a compensação de eventuais débitos que
  //         possam surgir em virtude deste Contrato ou de quaisquer outras
  //         operações realizadas com o BANCO CREFISA e/ou com qualquer (quaisquer)
  //         de sua(s) controladora(s)/controlada(s)/coligada(s), com eventuais
  //         créditos advindos e/ou existentes em – mas não se limitando a – suas
  //         contas de depósito à vista, garantias, aplicações, investimentos,
  //         CDBs, RDBs, DPGEs e outros créditos que permitam a perfeita e completa
  //         satisfação de eventuais débitos em aberto.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>13.1.1 </BoldTypography>O Verificado saldo a
  //         descoberto em conta-corrente, que esteja vencido, o BANCO CREFISA
  //         poderá incluir o nome e os dados dos Clientes nos Cadastros de
  //         Proteção ao Crédito.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>13.1.2 </BoldTypography>O Cliente autoriza o Banco
  //         Crefisa a priorizar a compensação do(s) débito(s) relativo(s) ao
  //         pagamento da(s) parcela(s) do(s) contrato(s) de empréstimo
  //         contratado(s) junto à Crefisa S/A – Crédito, Financiamento e
  //         Investimentos em detrimento de eventual(ais) débito(s) que porventura
  //         possa(m) ser lançado(s) na mesma data. O cliente está ciente que é de
  //         sua responsabilidade manter fundos suficientes em conta para honrar
  //         compromisso(s) com terceiro(s).
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>13.2 </BoldTypography>O Cliente autoriza, desde já, o
  //         envio de comunicações, por meio de e-mails e mensagens para seu
  //         telefone celular. O Cliente pode cancelar essa autorização a qualquer
  //         momento.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>13.3 </BoldTypography>O Cliente autoriza o BANCO
  //         CREFISA a consultar seus dados cadastrais perante os órgãos de
  //         proteção ao crédito, serviços de prevenção à fraudes externos e outros
  //         que se façam necessários para a realização da análise de seu cadastro,
  //         bem como anuem com o envio de informações e dados referentes às
  //         operações realizadas com o BANCO CREFISA e/ou com qualquer (quaisquer)
  //         de sua(s) controladora(s)/controlada(s)/coligada(s) para os Cadastros
  //         Positivos e Negativos, Cartórios de Protestos, Sistema de Informações
  //         de Crédito do Banco Central do Brasil (SCR),Conselho de Controle de
  //         Atividades Financeiras (COAF) e a outros órgãos, por força legal,
  //         judicial ou por qualquer outra medida, no efetivo exercício de seus
  //         direitos e/ou obrigações.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>13.4 </BoldTypography>
  //         Eventual tolerância ou concessão do BANCO CREFISA não implica em
  //         renúncia, perdão, novação ou alteração destes termos contratuais.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>13.5 </BoldTypography>O Cliente declara, sob as penas
  //         da lei, que as informações por eles prestadas e os documentos
  //         apresentados ao BANCO CREFISA são verdadeiros.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>13.6 </BoldTypography>
  //         No caso de o Cliente ser pessoa relativamente incapaz, nos termos da
  //         legislação civil, os seus responsáveis, ao assinarem a PAC, outorgam a
  //         assistência necessária para que o Cliente pratique todos os atos de
  //         movimentação da conta-corrente, bem como celebrem negócios bancários
  //         perfeitos com o BANCO CREFISA e com as sociedades de seu conglomerado.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>13.7 </BoldTypography>
  //         Fica eleito o foro da Comarca da Capital do Estado de São Paulo como
  //         competente para dirimir quaisquer questões oriundas deste Contrato.
  //       </ContractText>

  //       <br />
  //       <ContractText>
  //         <BoldTypography>14. TRATAMENTO DE DADOS PESSOAIS </BoldTypography>
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>14.1 </BoldTypography>O BANCO CREFISA S/A E CREFISA
  //         S/A CRÉDITO, FINANCIAMENTO E INVESTIMENTOS para o desempenho de suas
  //         atividades tratarão dados pessoais do CLIENTE para finalidades
  //         específicas e de acordo com as bases legais previstas na Lei Geral de
  //         Proteção de Dados Pessoais (Lei nº. 13.709/18), tais como: a) para a
  //         execução de contrato ou de procedimentos preliminares com o CLIENTE;
  //         b) para o cumprimento de obrigação legal ou regulatória; c) para o
  //         BANCO CREFISA S/A E CREFISA S/A CRÉDITO, FINANCIAMENTO E INVESTIMENTOS
  //         exercerem seus direitos em processos judiciais, administrativos ou
  //         arbitrais; d) para atenderem aos interesses legítimos do BANCO CREFISA
  //         S/A E CREFISA S/A CRÉDITO, FINANCIAMENTO E INVESTIMENTOS ou de
  //         terceiros; e) para a proteção do crédito; f) para a garantia da
  //         prevenção à fraude e à segurança do CLIENTE; e g) para oferta de
  //         outros produtos e/ou serviços bancários, destinados ao apoio e
  //         promoção de atividades do BANCO CREFISA S/A E CREFISA S/A CRÉDITO,
  //         FINANCIAMENTO E INVESTIMENTOS, visando a manutenção e aperfeiçoamento
  //         do relacionametno com o CLIENTE.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>14.2 </BoldTypography>O CLIENTE declara que está
  //         ciente de que para viabilizar a contratação de serviços e produtos
  //         junto ao BANCO CREFISA S/A E CREFISA S/A CRÉDITO, FINANCIAMENTO E
  //         INVESTIMENTOS, os seguintes dados pessoais serão utilizados,
  //         incluindo, mas não se limitando, aos seguintes dados: a) nome; b)
  //         sexo, c) data de nascimento, d) CPF; e) Documento de identificação (RG
  //         / CNH etc); f) nacionalidade; g) Nome dos pais, h) estado civil; i)
  //         Nome do cônjuge; j) endereço completo; k) telefone residencial e
  //         celular; l) cargo, m) dados de contato para fins de referência, bem
  //         como outros dados pessoais que poderão ser coletados, oportunamente,
  //         para viabilizar a contratação e a execução do presente contrato.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>14.3 </BoldTypography>O BANCO CREFISA S/A E CREFISA
  //         S/A CRÉDITO, FINANCIAMENTO E INVESTIMENTOS poderão compartilhar os
  //         dados pessoais do CLIENTE com empresas parceiras para fins específicos
  //         de marketing, como, por exemplo, com as empresas Crefisa CFI, Crefisa
  //         Seguros, Toscana (telemarketing), Panda (marketing), FAM, Nosso Vet,
  //         Doutor FAM, Adobe, além de outros fornecedores de processamento de
  //         dados, de tecnologia voltada à prevenção a fraudes, correspondentes no
  //         país e empresas ou escritórios especializados em cobrança de dívidas.
  //         Além disso, o CLIENTE declara estar CIENTE de que o BANCO CREFISA S/A
  //         E CREFISA S/A CRÉDITO, FINANCIAMENTO E INVESTIMENTOS, em observância a
  //         Resolução Conjunta nº. 6 de 2023, do Ministério da Fazenda/Conselho
  //         Monetário Nacional, realizarão o tratamento de dados decorrentes de
  //         indícios de ocorrências ou de tentativas de fraudes identificadas,
  //         podendo realizar o compartilhamento dessas informações com outras
  //         instituições do sistema financeiro, com a finalidade de prevenção à
  //         fraudes e segurança das operações. O BANCO CREFISA S/A E CREFISA S/A
  //         CRÉDITO, FINANCIAMENTO E INVESTIMENTOS poderão, ainda, fornecer os
  //         dados pessoais do CLIENTE sempre que estiver obrigada em virtude de
  //         obrigação legal ou regulatória, ato de autoridade competente ou ordem
  //         judicial.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>14.4 </BoldTypography>O CLIENTE, na qualidade de
  //         titular de dados pessoais, poderá, mediante requisição formal e por
  //         escrito, através dos canais de atendimento do BANCO CREFISA S/A E
  //         CREFISA S/A CRÉDITO, FINANCIAMENTO E INVESTIMENTOS e,
  //         preferencialmente pelo e-mail tratamentodedados@crefisa.com.br,
  //         exercer os seus direitos previstos na LGPD, para: a) a confirmação da
  //         existência de tratamento; b) o acesso aos dados; c) a correção de
  //         dados incompletos, inexatos ou desatualizados; d) a anonimização,
  //         bloqueio ou eliminação de dados pessoais desnecessários, excessivos ou
  //         tratados em desconformidade com a lei; e) eliminação dos dados
  //         pessoais; f) informação de compartilhamento de dados; g) informação
  //         sobre a possibilidade de não fornecer consentimento e sobre as
  //         consequências da negativa; h) revogação do consentimento; e i)
  //         esclarecimento de dúvidas em relação ao tratamento de seus dados
  //         pessoais. Após o término do presente contrato, os dados pessoais do
  //         CLIENTE poderão ser conservados pelo BANCO CREFISA S/A E CREFISA S/A
  //         CRÉDITO, FINANCIAMENTO E INVESTIMENTOS, para cumprimento de obrigações
  //         legais ou regulatórias, para o exercício regular de direitos em
  //         processo judiciais, administrativos e arbitral, nos prazos previstos
  //         nas legislações correspondentes.
  //       </ContractText>
  //       <ContractText>
  //         <BoldTypography>14.5 </BoldTypography>Para sanar eventuais dúvidas, o
  //         CLIENTE poderá acessar o Aviso de Privacidade do BANCO CREFISA S/A E
  //         CREFISA S/A CRÉDITO, FINANCIAMENTO E INVESTIMENTOS no endereço
  //         https://www.crefisa.com.br/privacidade-de-dados/
  //       </ContractText>

  //       <BoldContractText textAlign="center" mt="40px" mb="20px">
  //         SAC 0800 727 4884 / OUVIDORIA 0800 703 8891 / DEFICIENTES AUDITIVOS E
  //         DE FALA 0800 273 3374
  //       </BoldContractText>
  //     </Grid>
  //   );
  // }
};

export default DeclarationsContractSection;
