import { LoopOutlined, PhotoCamera, Warning } from "@mui/icons-material";
import { Button, Grid, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { StyledCircularProgress } from "../../../components/Loading/styles";
import { queryClientConfig } from "../../../config/react-query.config";
import { mailingKeys } from "../../../data/key-factories/mailing.qkeys";
import { useThemeWhiteLabel } from "../../../hooks/useThemeWhiteLabel";
import useWebcam from "../../../hooks/useWebcam";
import {
  MailingAdditionalInfoProps,
  MailingFileRequest,
} from "../../../shared/models/backend/mailing/mailing";
import {
  FileRequestDocumentEnum,
  FileTypeEnum,
} from "../../../shared/models/enums/files.enum";
import FileInput from "../../../shared/models/fileInput";
import useMailingOnBoardingService from "../../../shared/services/useMailingOnBoardingService";
import { Palette } from "../../../shared/theme/palette";
import {
  cameraConfig,
  isImageExtension,
} from "../../../shared/utils/webcam-utils";
import { FilePickerInput } from "../../Inquiries/components/styles";
import ContainerContent from "../components/ContainerContent";
import { EMailingStepsEnum, MailingStepProps } from "../index.types";
import {
  BoldTitleTypography,
  BoldTypography,
  CenterBox,
  OnboardingCardLowRadius,
  PhotoWebcamContainer,
  SubTitle500Typography,
  SubTitleInterTypography,
} from "../styles";
import { TakePhotoStepper } from "./TakePhotoStepper";

const TakePhotoMultiFile = ({
  setActiveStep,
  additionalInfo,
  photoToEdit,
}: {
  additionalInfo: MailingAdditionalInfoProps;
  photoToEdit?: "front" | "back";
} & MailingStepProps) => {
  const { id } = useParams();

  const hasFrontImage = !!additionalInfo?.documentFrontImageUrl;
  const hasImages = hasFrontImage && !!additionalInfo?.documentBackImageUrl;

  const [isLoading, setIsLoading] = useState(false);
  const [imageFront, setImageFront] = useState<FileInput | undefined>(
    hasFrontImage
      ? {
          fileName: "document-front.png",
          fileContent: additionalInfo?.documentFrontImageUrl,
        }
      : undefined
  );
  const [imageBack, setImageBack] = useState<FileInput | undefined>(
    hasImages
      ? {
          fileName: "document-back.png",
          fileContent: additionalInfo?.documentBackImageUrl,
        }
      : undefined
  );
  const [progress, setProgress] = useState<number>(0);
  const webcamRef = useRef(null);
  const fileFrontInputRef = useRef(null);
  const fileBackInputRef = useRef(null);
  const theme = useThemeWhiteLabel();
  const { permissionGranted, hasCamera, errorMessage } = useWebcam();
  const { sendMailingFile } = useMailingOnBoardingService();

  const needImageUpload = progress === 0 ? !imageFront : !imageBack;

  useEffect(() => {
    if (!photoToEdit) return;

    setProgress(photoToEdit === "back" ? 100 : 0);
  }, [photoToEdit]);

  useEffect(() => {
    if (hasFrontImage && !imageBack?.fileContent) {
      setProgress(50);
    }
  }, [hasFrontImage, imageBack?.fileContent]);

  const handlePickImageChange = (event, setImage) => {
    const file = event.target.files[0];

    if (file && isImageExtension(file.name)) {
      const reader = new FileReader();
      reader.onloadend = () =>
        setImage({ fileName: file.name, fileContent: reader.result as string });
      reader.readAsDataURL(file);
      return;
    }
    toast.error(
      "Por favor, selecione um arquivo de imagem válido (*.png, *.jpeg, *.jpg)."
    );
  };

  const getUploadData = (
    image: FileInput,
    fileType: FileTypeEnum.DocumentFront | FileTypeEnum.DocumentBack
  ): MailingFileRequest => {
    return {
      publicId: id,
      documentType: FileRequestDocumentEnum.RG,
      fileName: image.fileName,
      fileContent: image.fileContent,
      fileType,
    };
  };

  const handleUploadFrontImage = (isEditing?: boolean) => {
    if (imageFront.fileContent === additionalInfo?.documentFrontImageUrl) {
      setProgress(50);
      return;
    }
    const data = getUploadData(imageFront, FileTypeEnum.DocumentFront);
    setIsLoading(true);
    sendMailingFile(data)
      .then(() => {
        if (isEditing) {
          queryClientConfig.queryClient.refetchQueries({
            queryKey: mailingKeys.findOne(id),
          });
          setActiveStep(EMailingStepsEnum.PersonalDataForm);
          return;
        }
        setProgress(50);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUploadBackImage = (isEditing?: boolean) => {
    const data = getUploadData(imageBack, FileTypeEnum.DocumentBack);
    setIsLoading(true);
    sendMailingFile(data)
      .then(() => {
        setActiveStep(
          isEditing
            ? EMailingStepsEnum.PersonalDataForm
            : EMailingStepsEnum.VideoInstructions
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const editFlowMainButtons =
    photoToEdit === "front" ? (
      <Button
        color="primary"
        variant="contained"
        size="large"
        sx={{ paddingInline: "24px" }}
        disabled={
          imageFront?.fileContent === additionalInfo?.documentFrontImageUrl ||
          isLoading
        }
        startIcon={
          isLoading ? (
            <StyledCircularProgress size={16} />
          ) : imageFront?.fileContent &&
            imageFront?.fileContent !==
              additionalInfo?.documentFrontImageUrl ? undefined : (
            <PhotoCamera
              sx={{
                color:
                  imageFront?.fileContent ===
                  additionalInfo?.documentFrontImageUrl
                    ? "rgba(0, 0, 0, 0.26)"
                    : theme.alternativeColor,
              }}
            />
          )
        }
        onClick={() => {
          if (imageFront) {
            handleUploadFrontImage(true);
            return;
          }
          setImageFront({
            fileName: `documento-frente.png`,
            fileContent: webcamRef.current.getScreenshot(),
          });
        }}
      >
        {(!imageFront?.fileContent ||
          imageFront?.fileContent === additionalInfo?.documentFrontImageUrl) &&
          "Tirar foto da frente"}
        {imageFront?.fileContent &&
          imageFront?.fileContent !== additionalInfo?.documentFrontImageUrl &&
          "Salvar alteração"}
      </Button>
    ) : (
      <Button
        color="primary"
        variant="contained"
        size="large"
        sx={{ paddingInline: "24px" }}
        disabled={
          imageBack?.fileContent === additionalInfo?.documentBackImageUrl ||
          isLoading
        }
        startIcon={
          isLoading ? (
            <StyledCircularProgress size={16} />
          ) : imageBack?.fileContent &&
            imageBack?.fileContent !==
              additionalInfo?.documentBackImageUrl ? undefined : (
            <PhotoCamera
              sx={{
                color:
                  imageBack?.fileContent ===
                  additionalInfo?.documentBackImageUrl
                    ? "rgba(0, 0, 0, 0.26)"
                    : theme.alternativeColor,
              }}
            />
          )
        }
        onClick={() => {
          if (imageBack) {
            handleUploadBackImage(true);
            return;
          }
          setImageBack({
            fileName: `documento-verso.png`,
            fileContent: webcamRef.current.getScreenshot(),
          });
        }}
      >
        {(!imageBack?.fileContent ||
          imageBack?.fileContent === additionalInfo?.documentBackImageUrl) &&
          "Tirar foto do verso"}
        {imageBack?.fileContent &&
          imageBack?.fileContent !== additionalInfo?.documentBackImageUrl &&
          "Salvar alteração"}
      </Button>
    );

  const firstFlowMainButtons =
    progress === 0 ? (
      <Button
        color="primary"
        variant="contained"
        size="large"
        sx={{ paddingInline: "24px" }}
        disabled={isLoading}
        startIcon={
          isLoading ? (
            <StyledCircularProgress size={16} />
          ) : (
            <PhotoCamera sx={{ color: theme.alternativeColor }} />
          )
        }
        onClick={() => {
          if (imageFront) {
            handleUploadFrontImage();
            return;
          }
          setImageFront({
            fileName: `documento-frente.png`,
            fileContent: webcamRef.current.getScreenshot(),
          });
        }}
      >
        {imageFront ? "Tirar foto do verso" : "Tirar foto da frente"}
      </Button>
    ) : (
      <Button
        color="primary"
        variant="contained"
        size="large"
        sx={{ paddingInline: "24px" }}
        disabled={isLoading}
        startIcon={
          imageBack ? (
            isLoading ? (
              <StyledCircularProgress size={16} />
            ) : undefined
          ) : (
            <PhotoCamera sx={{ color: theme.alternativeColor }} />
          )
        }
        onClick={() => {
          if (imageBack) {
            handleUploadBackImage();
            return;
          }
          setImageBack({
            fileName: `documento-verso.png`,
            fileContent: webcamRef.current.getScreenshot(),
          });
          setProgress(100);
        }}
      >
        {imageBack ? "Continuar" : "Tirar foto do verso"}
      </Button>
    );

  return (
    <ContainerContent>
      <BoldTitleTypography align="center" mb="40px">
        Utilize a câmera do seu dispositivo para tirar a fotografia.
        Certifique-se de que as informações estão legíveis.
      </BoldTitleTypography>

      <OnboardingCardLowRadius sx={{ borderRadius: "10px !important" }}>
        <Grid container>
          <Grid item xs={12}>
            <Stack alignItems="center">
              <TakePhotoStepper progress={progress} maxWidth={807} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={8} order={0}>
            {errorMessage && !imageFront && !imageBack && (
              <CenterBox>
                <Warning style={{ color: Palette.red }} fontSize="small" />
                <SubTitle500Typography sx={{ marginLeft: "8px" }}>
                  {errorMessage}
                </SubTitle500Typography>
              </CenterBox>
            )}
            {permissionGranted && hasCamera && needImageUpload && (
              <PhotoWebcamContainer
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                  ...cameraConfig,
                  facingMode: "environment",
                }}
                mirrored={!isMobile}
              />
            )}
            {(progress === 0
              ? imageFront?.fileContent
              : imageBack?.fileContent) && (
              <img
                src={
                  progress === 0
                    ? imageFront.fileContent
                    : imageBack.fileContent
                }
                alt="foto do documento"
                style={{
                  width: "100%",
                  height: "auto",
                  transform: isMobile ? "scaleX(1)" : "scaleX(-1)",
                }}
              />
            )}
            <FilePickerInput
              ref={fileFrontInputRef}
              type="file"
              onChange={(event) => handlePickImageChange(event, setImageFront)}
              accept="image/png, image/jpeg"
            />
            <FilePickerInput
              ref={fileBackInputRef}
              type="file"
              onChange={(event) => handlePickImageChange(event, setImageBack)}
              accept="image/png, image/jpeg"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            pl={{ xs: "0px", md: "48px" }}
            order={{ xs: 2, md: 1 }}
          >
            <Stack gap="16px" height="100%" justifyContent="center">
              <Button
                color="primary"
                variant="outlined"
                size="large"
                disabled={needImageUpload || isLoading}
                startIcon={
                  <LoopOutlined
                    sx={{
                      color: needImageUpload
                        ? "rgba(0, 0, 0, 0.26)"
                        : theme.primaryColor,
                    }}
                  />
                }
                onClick={() => {
                  if (progress === 0) {
                    setImageFront(undefined);
                    return;
                  }
                  setProgress(50);
                  setImageBack(undefined);
                }}
              >
                Trocar Foto
              </Button>

              {hasImages ? editFlowMainButtons : firstFlowMainButtons}

              <Button
                color="primary"
                variant="outlined"
                size="large"
                disabled={isLoading}
                onClick={() =>
                  setActiveStep(
                    hasImages
                      ? EMailingStepsEnum.PersonalDataForm
                      : EMailingStepsEnum.DocumentInstructions
                  )
                }
              >
                {hasImages ? "Cancelar" : "Voltar para instruções"}
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} order={{ xs: 1, md: 2 }}>
            <SubTitleInterTypography
              textAlign="center"
              maxWidth={969}
              mt={{ xs: "24px", md: "40px" }}
              mb={{ xs: "24px", md: "0px" }}
            >
              {hasImages &&
                additionalInfo?.documentFrontImageUrl ===
                  imageFront?.fileContent &&
                additionalInfo?.documentBackImageUrl ===
                  imageBack?.fileContent && (
                  <span>
                    Para trocar a foto, basta clicar no botão{" "}
                    <BoldTypography>“Trocar foto”</BoldTypography>. Para
                    desistir da alteração{" "}
                    <BoldTypography>clique no botão “Cancelar”.</BoldTypography>
                  </span>
                )}

              {hasImages &&
                additionalInfo?.documentFrontImageUrl &&
                !imageFront?.fileContent && (
                  <span>
                    Quando o documento estiver bem posicionado na câmera, clique
                    no botão{" "}
                    <BoldTypography>“Tirar foto da frente”.</BoldTypography>
                  </span>
                )}

              {hasImages &&
                additionalInfo?.documentBackImageUrl &&
                !imageBack?.fileContent && (
                  <span>
                    Quando o documento estiver bem posicionado na câmera, clique
                    no botão{" "}
                    <BoldTypography>“Tirar foto do verso”.</BoldTypography>
                  </span>
                )}

              {hasImages &&
                imageFront?.fileContent &&
                imageFront?.fileContent !==
                  additionalInfo?.documentFrontImageUrl && (
                  <span>
                    Caso queira trocar a foto, basta clicar no botão{" "}
                    <BoldTypography>“Trocar foto”</BoldTypography>. Caso a foto
                    tenha ficado satisfatória,{" "}
                    <BoldTypography>
                      clique no botão “Salvar alteração”
                    </BoldTypography>{" "}
                    para confirmar a alteração.
                  </span>
                )}

              {hasImages &&
                imageBack?.fileContent &&
                imageBack?.fileContent !==
                  additionalInfo?.documentBackImageUrl && (
                  <span>
                    Caso queira trocar a foto, basta clicar no botão{" "}
                    <BoldTypography>“Trocar foto”</BoldTypography>. Caso a foto
                    tenha ficado satisfatória,{" "}
                    <BoldTypography>
                      clique no botão “Salvar alteração”
                    </BoldTypography>{" "}
                    para confirmar a alteração.
                  </span>
                )}

              {!hasImages && progress === 0 && !imageFront && (
                <span>
                  Quando o documento estiver bem posicionado na câmera, clique
                  no botão{" "}
                  <BoldTypography>“Tirar foto da frente”.</BoldTypography>
                </span>
              )}

              {!hasImages && progress === 0 && imageFront && (
                <span>
                  Caso queira trocar a foto, basta clicar no botão{" "}
                  <BoldTypography>“Trocar foto”</BoldTypography>. Caso a foto
                  tenha ficado satisfatória,{" "}
                  <BoldTypography>
                    clique no botão “Tirar foto do verso”.
                  </BoldTypography>
                </span>
              )}
              {!hasImages && progress === 50 && !imageBack && (
                <span>
                  Quando o documento estiver bem posicionado na câmera, clique
                  no botão{" "}
                  <BoldTypography>“Tirar foto do verso”.</BoldTypography>
                </span>
              )}

              {!hasImages && progress === 100 && imageBack && (
                <span>
                  Caso queira trocar a foto, basta clicar no botão{" "}
                  <BoldTypography>“Trocar foto”</BoldTypography>. Caso a foto
                  tenha ficado satisfatória,{" "}
                  <BoldTypography>clique no botão “Continuar”</BoldTypography>{" "}
                  para realizar as próximas etapas.
                </span>
              )}
            </SubTitleInterTypography>
          </Grid>
        </Grid>
      </OnboardingCardLowRadius>
    </ContainerContent>
  );
};

export default TakePhotoMultiFile;
