import { Stack } from "@mui/material";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";
import RotateImg from "../../../../shared/assets/rotate-phone.png";
import { SubTitleTypography, TitleTypography } from "../../styles";

export type MandatoryLandscapeProps = {
  title: string;
  description: string;
};

export const MandatoryLandscape = ({
  title,
  description,
}: MandatoryLandscapeProps) => {
  const theme = useThemeWhiteLabel();

  return (
    <Stack gap="48px" pt="64px" alignItems="center">
      <img
        src={RotateImg}
        alt="rotate your phone to landscape mode"
        width={235}
      />
      <div>
        <TitleTypography variant="h1" color={theme.primaryColor} align="center">
          {title}
        </TitleTypography>
        <SubTitleTypography align="center">{description}</SubTitleTypography>
      </div>
    </Stack>
  );
};
