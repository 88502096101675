import { PhotoCamera } from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetMailingQuery } from "../../../data/queries/mailing.query";
import { useThemeWhiteLabel } from "../../../hooks/useThemeWhiteLabel";
import ContainerContent from "../components/ContainerContent";
import { EMailingStepsEnum, MailingStepProps } from "../index.types";
import { InstructionStepVariantsEnum } from "../InstructionsStep";
import {
  OnboardingCard,
  RadioLabel,
  SubTitleInterTypography,
  SubTitleTypography,
  TitleTypography,
} from "../styles";
import { getDocVariant } from "../utils";

type DocumentTypeStepProps = {
  documentType: InstructionStepVariantsEnum;
  onChangeDocumentType: Dispatch<SetStateAction<InstructionStepVariantsEnum>>;
} & MailingStepProps;

const DocumentTypeStep = ({
  documentType,
  onChangeDocumentType,
  setActiveStep,
}: DocumentTypeStepProps) => {
  const { id } = useParams();
  const theme = useThemeWhiteLabel();
  const { data: mailingData } = useGetMailingQuery(id);

  useEffect(() => {
    if (!mailingData?.additionalInfo?.documentType || !!documentType) return;
    const docType = mailingData?.additionalInfo?.documentType;
    onChangeDocumentType(getDocVariant(docType));
  }, [mailingData?.additionalInfo?.documentType, documentType]);

  return (
    <ContainerContent centered>
      <Grid container spacing={{ xs: 6, sm: 2 }}>
        <Grid item xs={12} md={6}>
          <TitleTypography
            color={theme.primaryColor}
            mt={{ xs: 0, md: "70px" }}
          >
            Atenção:
          </TitleTypography>
          <SubTitleInterTypography maxWidth={412}>
            Para continuar, precisamos de uma foto do seu documento de
            identificação. Isso nos ajudará a validar sua identidade com
            segurança. Informe o tipo de documento que será utilizado e siga as
            instruções na tela para capturar a imagem.
          </SubTitleInterTypography>
        </Grid>

        <Grid item xs={12} md={6}>
          <OnboardingCard sx={{ maxWidth: 524 }}>
            <Stack gap="32px">
              <SubTitleTypography fontWeight={500}>
                Escolha o tipo de documento
              </SubTitleTypography>
              <FormControl>
                <RadioGroup
                  value={documentType}
                  name="docs-radio-buttons-group"
                  onChange={(event) => {
                    onChangeDocumentType(
                      event.target.value as InstructionStepVariantsEnum
                    );
                  }}
                >
                  <RadioLabel
                    value={InstructionStepVariantsEnum.rg}
                    control={<Radio size="small" />}
                    label="RG - Registro Geral"
                  />
                  <RadioLabel
                    value={InstructionStepVariantsEnum.cnh}
                    control={<Radio size="small" />}
                    label="CNH - Carteira Nacional de Habilitação"
                  />
                  <RadioLabel
                    value={InstructionStepVariantsEnum.outro}
                    control={<Radio size="small" />}
                    label="Outro (CTPS, OAB, Militar, Passaporte)"
                  />
                </RadioGroup>
              </FormControl>
              <Button
                color="primary"
                variant="contained"
                size="large"
                disabled={!documentType}
                startIcon={
                  <PhotoCamera sx={{ color: theme.alternativeColor }} />
                }
                onClick={() =>
                  setActiveStep(EMailingStepsEnum.DocumentInstructions)
                }
                fullWidth
              >
                Tirar foto do documento
              </Button>
            </Stack>
          </OnboardingCard>
        </Grid>
      </Grid>
    </ContainerContent>
  );
};

export default DocumentTypeStep;
