import { Button, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import Typography from "../../../../components/Typography";
import CtpsBack from "../../../../shared/assets/ctps-back-example.png";
import CtpsFront from "../../../../shared/assets/ctps-front-example.png";
import { StyledDivButton } from "../../onBoardingVideo/styles";
import { ImageVerticalContainer } from "./styles";

interface InstructionsCTPSProps {
  navigateToNext: () => void;
  title?: string;
}

const InstructionsOnBoardingVideoCTPS: React.FC<InstructionsCTPSProps> = ({
  navigateToNext,
  title,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Typography variant="h1" color="green">
        {title || "Instruções para a foto do documento"}
      </Typography>
      <Typography variant="caption">
        Utilize um documento de Carteira de Trabalho físico (documentos digitais
        não são permitidos). Primeiro você deverá tirar a foto da primeira
        página (com foto) e, em seguida, da página de Qualificação Civil.
      </Typography>
      <Typography variant="caption">
        A primeira página pode conter ou não a digital do portador.
      </Typography>
      <ImageVerticalContainer isSmallScreen={isSmallScreen} src={CtpsFront} />
      <Typography variant="caption">
        A página de Qualificação Civil contém os dados pessoais do portador.
      </Typography>
      <ImageVerticalContainer isSmallScreen={isSmallScreen} src={CtpsBack} />
      <Typography variant="caption">
        Clique no botão “Próximo” abaixo para prosseguir.
      </Typography>
      <StyledDivButton>
        <Button variant={"contained"} color="primary" onClick={navigateToNext}>
          {"Próximo"}
        </Button>
      </StyledDivButton>
    </>
  );
};

InstructionsOnBoardingVideoCTPS.propTypes = {
  navigateToNext: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default InstructionsOnBoardingVideoCTPS;
