import { Box, Button, Stack } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { StyledCircularProgress } from "../../../components/Loading/styles";
import usePrivateUserData from "../../../hooks/usePrivateUserData";
import { useThemeWhiteLabel } from "../../../hooks/useThemeWhiteLabel";
import useMailingOnBoardingService from "../../../shared/services/useMailingOnBoardingService";
import ContainerContent from "../components/ContainerContent";
import { EMailingStepsEnum, MailingStepProps } from "../index.types";
import {
  MailingList,
  MailingListItem,
  OnboardingCard,
  PoppinsSmallBoldTypography,
  SubTitleTypography,
  TitleTypography,
} from "../styles";

const AgreementStep = ({ setActiveStep }: MailingStepProps) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useThemeWhiteLabel();
  const { ip, latitude, longitude } = usePrivateUserData();
  const { sendMailingDigitalData } = useMailingOnBoardingService();

  const handleAllowData = (allowsDataSharing: boolean) => {
    setIsLoading(true);
    sendMailingDigitalData(id, allowsDataSharing ? { allowsDataSharing, latitude, longitude, ip } : {allowsDataSharing})
      .then(() => {
        setActiveStep(EMailingStepsEnum.DocumentChooseType);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ContainerContent centered>
      <OnboardingCard sx={{ maxWidth: 836 }}>
        <Box maxWidth={613}>
          <TitleTypography
            variant="h1"
            color={theme.primaryColor}
            align="center"
          >
            Proteção da sua identidade
          </TitleTypography>
          <SubTitleTypography variant="h2" mb="24px">
            Para garantir a segurança da sua identidade, precisamos coletar
            algumas informações do seu dispositivo. Isso nos ajudará a proteger
            seus dados.
          </SubTitleTypography>

          <PoppinsSmallBoldTypography>
            Informações que precisamos:
          </PoppinsSmallBoldTypography>

          <MailingList sx={{ marginBottom: "0px !important" }}>
            <MailingListItem>Localização</MailingListItem>
            <MailingListItem>Endereço de IP</MailingListItem>
          </MailingList>

          <Stack
            direction={{ xs: "column-reverse", sm: "row" }}
            justifyContent="center"
            gap="24px"
            mt="48px"
          >
            <Button
              color="primary"
              variant="outlined"
              size="large"
              disabled={isLoading}
              onClick={() => handleAllowData(false)}
            >
              Negar
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={isLoading}
              startIcon={
                isLoading ? <StyledCircularProgress size={16} /> : undefined
              }
              onClick={() => handleAllowData(true)}
            >
              Permitir
            </Button>
          </Stack>
        </Box>
      </OnboardingCard>
    </ContainerContent>
  );
};

export default AgreementStep;
