import { toast } from "react-toastify";

export class ValidationUtils {
  static validatePhone(phone: string, throwError = false): boolean {
    if (!phone) {
      throwError && toast.error("Telefone não pode ser vazio!");
      return false;
    }
    if (phone.replace(/\D/g, "").length !== 11) {
      throwError && toast.error("Telefone inválido!");
      return false;
    }
    return true;
  }
  static validateDDIPhone(phone: string, throwError = false): boolean {
    if (!phone) {
      throwError && toast.error("Telefone não pode ser vazio!");
      return false;
    }
    if (phone.replace(/\D/g, "").length !== 13) {
      throwError && toast.error("Telefone inválido!");
      return false;
    }
    return true;
  }
  static validateBussinessName = (
    bussinessName: string,
    throwError: boolean = true
  ) => {
    if (!bussinessName) {
      throwError && toast.error("Nome da empresa não pode ser vazio!");
      return false;
    }
    return true;
  };

  static validateCnpj(cnpj: string, throwError = false): boolean {
    if (!cnpj) {
      throwError && toast.error("CNPJ não pode ser vazio!");
      return false;
    }
    if (cnpj.replace(/\D/g, "").length !== 14) {
      throwError && toast.error("CNPJ inválido!");
      return false;
    }
    return true;
  }

  static validateCpf(cpf: string): boolean {
    if (!cpf) {
      return false;
    }
    return cpf.replace(/\D/g, "").length === 11;
  }

  static validateCep(cep: string, throwError = false): boolean {
    if (!cep) {
      throwError && toast.error("CEP não pode ser vazio!");
      return false;
    }
    if (cep.replace(/\D/g, "").length !== 8) {
      throwError && toast.error("CEP inválido!");
      return false;
    }
    return true;
  }

  static validateCardNumber(cardNumber: string, throwError = false): boolean {
    if (!cardNumber) {
      throwError && toast.error("Número do cartão não pode ser vazio!");
      return false;
    }
    if (cardNumber.replace(/\D/g, "").length !== 16) {
      throwError && toast.error("Número do cartão inválido!");
      return false;
    }
    return true;
  }

  static validateCardDueDate(cardDueDate: string, throwError = false): boolean {
    if (!cardDueDate) {
      throwError && toast.error("Data de vencimento não pode ser vazio!");
      return false;
    }
    if (!/^\d{2}\/\d{2}$/.test(cardDueDate)) {
      throwError && toast.error("Data de vencimento inválida!");
      return false;
    }
    return true;
  }

  static validateCardHolderName(
    cardHolderName: string,
    throwError = false
  ): boolean {
    if (!cardHolderName) {
      throwError && toast.error("Nome do titular não pode ser vazio!");
      return false;
    }
    if (cardHolderName.split(" ").length < 2) {
      throwError && toast.error("Nome do titular inválido!");
      return false;
    }
    return true;
  }

  static validateCardCvv(cardCvv: string, throwError = false): boolean {
    if (!cardCvv) {
      throwError && toast.error("CVV não pode ser vazio!");
      return false;
    }
    if (cardCvv.replace(/\D/g, "").length !== 3) {
      throwError && toast.error("CVV inválido!");
      return false;
    }
    return true;
  }

  static validateCpfCnpj(cpfCnpj: string, throwError = false): boolean {
    if (!cpfCnpj) {
      throwError && toast.error("CPF/CNPJ não pode ser vazio!");
      return false;
    }
    if (cpfCnpj.replace(/\D/g, "").length === 11) {
      return ValidationUtils.validateCpf(cpfCnpj);
    }
    if (cpfCnpj.replace(/\D/g, "").length === 14) {
      return ValidationUtils.validateCnpj(cpfCnpj);
    }
    throwError && toast.error("CPF/CNPJ inválido!");
    return false;
  }

  static validateState(state: string, throwError = false): boolean {
    if (!state) {
      throwError && toast.error("Estado não pode ser vazio!");
      return false;
    }
    if (state.length !== 2) {
      throwError && toast.error("Estado inválido!");
      return false;
    }
    return true;
  }

  static validateCity(city: string, throwError = false): boolean {
    if (!city) {
      throwError && toast.error("Cidade não pode ser vazio!");
      return false;
    }
    return true;
  }

  static validateAddress(address: string, throwError = false): boolean {
    if (!address) {
      throwError && toast.error("Endereço não pode ser vazio!");
      return false;
    }
    return true;
  }

  static validateAddressNumber(
    addressNumber: string,
    throwError = false
  ): boolean {
    if (!addressNumber) {
      throwError && toast.error("Número não pode ser vazio!");
      return false;
    }
    return true;
  }

  static validateNeighborhood(
    neighborhood: string,
    throwError = false
  ): boolean {
    if (!neighborhood) {
      throwError && toast.error("Bairro não pode ser vazio!");
      return false;
    }
    return true;
  }

  static validateEmail(email: string, throwError = false): boolean {
    if (!email) {
      throwError && toast.error("Email não pode ser vazio!");
      return false;
    }

    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!regex.test(email)) {
      throwError && toast.error("Email inválido!");
      return false;
    }
    return true;
  }

  static validateLogin(login: string, throwError = false): boolean {
    if (!login) {
      throwError && toast.error("Login não pode ser vazio!");
      return false;
    }
    return true;
  }

  static validatePassword(
    password: string,
    throwError = false,
    validateRules = false
  ): boolean {
    if (!password) {
      throwError && toast.error("Deve ser informado a senha");
      return false;
    }
    if (validateRules && password.length < 8) {
      throwError && toast.error("A senha deve ter ao menos 8 caracteres.");
      return false;
    }
    if (validateRules && !/[A-Z]/.test(password)) {
      throwError &&
        toast.error("A senha deve conter ao menos uma letra maiúscula.");
      return false;
    }
    if (validateRules && !/[0-9]/.test(password)) {
      throwError && toast.error("A senha deve conter ao menos um número.");
      return false;
    }
    if (validateRules && !/[!@#$%^&*]/.test(password)) {
      throwError &&
        toast.error("A senha deve conter ao menos um caractere especial.");
      return false;
    }
    return true;
  }

  static validatePasswordConfirmation(
    password: string,
    passwordConfirmation: string,
    throwError = false
  ): boolean {
    if (password !== passwordConfirmation) {
      throwError && toast.error("As senhas não conferem!");
      return false;
    }
    return true;
  }

  static validateUserName = (name: string, throwError: boolean = true) => {
    if (!name) {
      throwError && toast.error("Nome não pode ser vazio!");
      return false;
    }
    return true;
  };

  static validateBrazilianStringDate = (dateString: string) => {
    const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    const match = dateString.match(regex);

    if (!match) return false;

    const [_, day, month, year] = match.map(Number);

    // Verifica se o mês está entre 1 e 12
    if (month < 1 || month > 12) return false;

    // Cria um objeto Date para validar o dia/mês/ano
    const date = new Date(year, month - 1, day);

    // Verifica se a data gerada corresponde aos valores originais
    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  };
}
