import { Button } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import DragAndDropFilePicker from "../../../../components/DragAndDropFilePicker";
import Typography from "../../../../components/Typography";
import { FileTypeEnum } from "../../../../shared/models/enums/files.enum";
import FileInput from "../../../../shared/models/fileInput";
import usePayrollLoanOnboardingService from "../../../../shared/services/usePayrollLoanOnboardingService";
import { InstructionsText } from "../../../../theme/GlobalStyles";
import { Container } from "../../../InquiryDetails/styles";
import { StyledDivButton } from "../../onBoardingVideo/styles";

interface PayrollOnboardingDocumentFileUploadProps {
  publicId: string;
  documentType: string;
  nextStep: () => void;
  previousStep: () => void;
  docOwnerInfoText: string;
}

const PayrollOnboardingDocumentFileUpload = ({
  ...props
}: PayrollOnboardingDocumentFileUploadProps) => {
  const { sendFilesPayRollVideoOnBoarding } = usePayrollLoanOnboardingService();
  const [files, setFiles] = useState<FileInput[]>([]);

  const getDocType = () => {
    switch (props.documentType) {
      case "Passaporte":
        return 4;
      case "CNH":
        return 2;
      case "Carteira de Trabalho":
        return 3;
      default:
        return 1;
    }
  };
  const saveFile = async () => {
    if (files.length === 0)
      return toast.error("Selecione um arquivo antes de prosseguir.");
    const requests = files.map((file) => ({
      fileName: file.fileName,
      fileContent: file.fileContent,
      fileType: FileTypeEnum.SingleFileDocument,
      documentType: getDocType(),
    }));
    await Promise.all(
      requests.map((request) =>
        sendFilesPayRollVideoOnBoarding(request, props.publicId)
      )
    );
    props.nextStep();
  };

  return (
    <Container>
      <Typography variant="h1">Envio de Arquivos</Typography>
      <InstructionsText>
        Clique no botão abaixo para selecionar um arquivo no seu computador.
        <p></p>
        Certifique-se de que as informações estão legíveis.
      </InstructionsText>
      <InstructionsText>{props.docOwnerInfoText}</InstructionsText>
      <DragAndDropFilePicker onFileChange={setFiles}></DragAndDropFilePicker>
      <InstructionsText>
        Após selecionar um arquivo,{" "}
        <b>clique no botão abaixo para prosseguir.</b>
      </InstructionsText>
      <StyledDivButton>
        <Button onClick={props.previousStep} variant={"text"}>
          {"Voltar"}
        </Button>
        <Button
          variant={"contained"}
          disabled={files.length === 0}
          color="primary"
          onClick={() => saveFile()}
        >
          {"Próximo"}
        </Button>
      </StyledDivButton>
    </Container>
  );
};
export default PayrollOnboardingDocumentFileUpload;
