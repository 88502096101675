import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip, TooltipProps } from "@mui/material";
import { ReactNode } from "react";

type TooltipHintProps = {
  hint: ReactNode;
  width?: string;
  maxWidth?: string;
  placement?: TooltipProps["placement"];
  withAdjustment?: boolean;
};

export const TooltipHint = ({
  hint,
  width = "500px",
  maxWidth = "500px",
  placement = "right-start",
  withAdjustment = false,
}: TooltipHintProps) => {
  return (
    <Tooltip
      title={hint}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: "12px",
            width,
            maxWidth,
          },
        },
      }}
    >
      <HelpOutlineIcon
        sx={{
          marginBottom: !withAdjustment ? "-4px" : "0px",
          marginLeft: !withAdjustment ? "4px" : "0px",
          cursor: "pointer",
        }}
      />
    </Tooltip>
  );
};
