import { Button } from "@mui/material";
import DragAndDropFilePicker from "../../../../components/DragAndDropFilePicker";
import FileInput from "../../../../shared/models/fileInput";
import {
  HorizontalButtonsContainer,
  InstructionsText,
  InstructionsTextBold,
  ScreenWithSubHeaderContainer,
} from "../../../../theme/GlobalStyles";

interface DocInquiryFileUploadProps {
  withSelfie?: boolean;
  onBack: () => void;
  enableNext: boolean;
  onNext: () => void;
  onChangePhoto: (
    name: string,
    buffer: string,
    type: "front" | "back" | "selfie"
  ) => void;
}

const DocInquiryFileUpload = ({ ...props }: DocInquiryFileUploadProps) => {
  const handleFileChange = (files: FileInput[]) => {
    files.forEach((file, index) => {
      const type = index === 0 ? "front" : "back";
      props.onChangePhoto(file.fileName, file.fileContent, type);
    });
  };
  return (
    <ScreenWithSubHeaderContainer>
      <InstructionsTextBold> Envio do documento </InstructionsTextBold>
      <InstructionsText>
        Clique no botão abaixo para selecionar um arquivo no seu computador.
        Certifique-se de que as informações estão legíveis.
      </InstructionsText>
      <DragAndDropFilePicker onFileChange={handleFileChange} />
      {props.withSelfie && (
        <>
          <InstructionsTextBold> Envio da Selfie </InstructionsTextBold>
          <DragAndDropFilePicker
            onFileChange={(files) =>
              files.forEach((file) =>
                props.onChangePhoto(file.fileName, file.fileContent, "selfie")
              )
            }
          />
        </>
      )}
      <InstructionsText>
        {" "}
        Após selecionar um arquivo,{" "}
        <b>clique no botão abaixo para prosseguir.</b>
      </InstructionsText>
      <HorizontalButtonsContainer>
        <Button onClick={props.onBack} variant={"text"}>
          {"Voltar"}
        </Button>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={props.onNext}
          disabled={!props.enableNext}
        >
          {"Prosseguir"}
        </Button>
      </HorizontalButtonsContainer>
    </ScreenWithSubHeaderContainer>
  );
};
export default DocInquiryFileUpload;
