import { CameraAlt as CameraIcon, Sync as SyncIcon } from "@mui/icons-material";
import WarningIcon from "@mui/icons-material/Warning";
import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Webcam from "react-webcam";
import TakePhotoStepper from "../../../../components/ProgressStepper/index";
import useWebcam from "../../../../hooks/useWebcam";
import { DocumentType } from "../../../../shared/models/document-type";
import FileInput from "../../../../shared/models/fileInput";
import { Palette } from "../../../../shared/theme/palette";
import {
  cameraConfig,
  isImageExtension,
} from "../../../../shared/utils/webcam-utils";
import {
  HorizontalButtonsContainer,
  InstructionsText,
  ScreenWithSubHeaderContainer,
} from "../../../../theme/GlobalStyles";
import { EDocumentInquiryStep } from "../../DocumentInquiry";
import {
  DocumentImagePhoto,
  DocumentInquiryPhotoContainer,
  DocumentInquiryTakePhotoContainer,
  NoCameraAlertContainer,
  NoCameraAlertText,
} from "../styles";

interface TakeDocumentPhotoProps {
  photoType: "front" | "back" | "selfie";
  docType: DocumentType;
  currentStep: EDocumentInquiryStep;
  withSelfie?: boolean;
  onBack: () => void;
  onNext: () => void;
  onChangePhoto: (
    name: string,
    buffer: string,
    type: "front" | "back" | "selfie"
  ) => void;
  loading: boolean;
}

const TakeDocumentPhoto = ({ ...props }: TakeDocumentPhotoProps) => {
  const [image, setImage] = useState<FileInput>();
  const webcamRef = useRef(null);
  const fileInputRef = useRef(null);
  const { permissionGranted, hasCamera, errorMessage } = useWebcam();
  useEffect(() => {
    console.log({ image });
    if (image)
      props.onChangePhoto(image.fileName, image.fileContent, props.photoType);
  }, [image]);

  const handlePickImageChange = (event) => {
    const file = event.target.files[0];

    if (file && isImageExtension(file.name)) {
      const reader = new FileReader();
      reader.onloadend = () =>
        setImage({ fileName: file.name, fileContent: reader.result as string });
      reader.readAsDataURL(file);
      return;
    }
    toast.error(
      "Por favor, selecione um arquivo de imagem válido (*.png, *.jpeg, *.jpg)."
    );
  };

  return (
    <ScreenWithSubHeaderContainer>
      <DocumentInquiryTakePhotoContainer>
        <TakePhotoStepper
          currentStep={props.currentStep}
          docType={props.docType}
          withSelfie={props.withSelfie}
        />
        <InstructionsText>
          {
            "Clique no botão abaixo para tirar a foto. Certifique-se de que as informações estão legíveis."
          }
        </InstructionsText>
        {image?.fileContent && (
          <DocumentImagePhoto
            src={image.fileContent}
            alt={props.photoType}
            width={640}
            height={320}
          ></DocumentImagePhoto>
        )}
        {permissionGranted && hasCamera && !image && (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={cameraConfig}
            width={640}
            height={320}
          />
        )}
        {errorMessage && !image && (
          <NoCameraAlertContainer>
            <WarningIcon style={{ color: Palette.red }} fontSize="small" />
            <NoCameraAlertText>{errorMessage}</NoCameraAlertText>
          </NoCameraAlertContainer>
        )}
        <DocumentInquiryPhotoContainer>
          <Button
            size={"large"}
            onClick={() => {
              if (image) {
                setImage(undefined);
                return;
              }
              setImage({
                fileName: `${props.photoType}.png`,
                fileContent: webcamRef.current.getScreenshot(),
              });
            }}
            variant={"contained"}
            color={"secondary"}
            startIcon={
              image ? (
                <SyncIcon />
              ) : (
                <CameraIcon
                  color={
                    props.loading || !permissionGranted || !hasCamera
                      ? "disabled"
                      : "warning"
                  }
                />
              )
            }
            disabled={props.loading || !permissionGranted || !hasCamera}
          >
            {image ? "Tirar outra foto" : "Tirar foto"}
          </Button>
        </DocumentInquiryPhotoContainer>
        <InstructionsText>
          Caso a foto tenha ficado satisfatória{" "}
          <b>clique no botão abaixo para prosseguir.</b>
        </InstructionsText>
        <input
          hidden
          ref={fileInputRef}
          type="file"
          onChange={handlePickImageChange}
          accept="image/png, image/jpeg"
        />
        <HorizontalButtonsContainer>
          <Button onClick={props.onBack} variant={"text"}>
            {"Voltar"}
          </Button>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={props.onNext}
            disabled={!image || props.loading}
          >
            {"Prosseguir"}
          </Button>
        </HorizontalButtonsContainer>
      </DocumentInquiryTakePhotoContainer>
    </ScreenWithSubHeaderContainer>
  );
};
export default TakeDocumentPhoto;
