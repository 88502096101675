import { Box } from "@mui/material";
import { useEffect, useState } from "react";

import { Document, Page, pdfjs } from "react-pdf";
import { useCrefisaWhiteLabelData } from "../../hooks/useCrefisaWhiteLabelData";
import { useThemeWhiteLabel } from "../../hooks/useThemeWhiteLabel";
import { GlobalLoader } from "../GlobalLoader/GlobalLoader";
import classes from "./pdf-viewer.module.css";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.mjs",
//   import.meta.url
// ).toString();
pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.mjs";

export const PdfViewer = ({ pdfUrl }: { pdfUrl: string }) => {
  const [showContent, setShowContent] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const { logo, name } = useCrefisaWhiteLabelData();
  const theme = useThemeWhiteLabel();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    // small timeout to ensure pdf worker is set correctly before the component uses it
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (!showContent)
    return (
      <GlobalLoader
        logo={logo}
        alt={`${name}-logo`}
        color={theme.primaryColor}
      />
    );

  return (
    <Box className={classes.contractBox}>
      <Document
        file={pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error) =>
          console.error("PDF load error:", error.message, error)
        }
      >
        {Array.from(new Array(numPages), (_, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </Box>
  );
};
