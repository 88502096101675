import { FileRequestDocumentEnum } from "../../shared/models/enums/files.enum";
import { InstructionStepVariantsEnum } from "./InstructionsStep";

export const getDocVariant = (
  fileType?: FileRequestDocumentEnum
): InstructionStepVariantsEnum | undefined => {
  if (!fileType) return undefined;
  if (fileType === FileRequestDocumentEnum.RG)
    return InstructionStepVariantsEnum.rg;
  if (fileType === FileRequestDocumentEnum.CNH)
    return InstructionStepVariantsEnum.cnh;
  return InstructionStepVariantsEnum.outro;
};

export function formatCPF(cpf) {
  if (!cpf) return cpf;
  // Remove quaisquer caracteres que não sejam dígitos
  const result = cpf.replace(/\D/g, "");

  // Verifica se o CPF tem 11 dígitos
  if (result.length !== 11) {
    return cpf;
  }

  // Aplica a formatação
  return result.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function formatNumeroBeneficio(numeroBeneficio) {
  if (!numeroBeneficio) return numeroBeneficio;
  const result = numeroBeneficio.replace(/\D/g, "");

  // Verifica se o CPF tem 11 dígitos
  if (result.length !== 10) {
    return numeroBeneficio;
  }

  // Aplica a formatação
  return result.replace(/(\d{3})(\d{3})(\d{3})(\d)/, "$1.$2.$3-$4");
}

export function formatBrazilianPhoneNumber(phone) {
  if (!phone) return phone;
  // Remove any non-numeric characters
  const cleanedPhone = phone.replace(/\D/g, "");

  // Check if the input has exactly 11 digits
  if (cleanedPhone.length !== 11) {
    return phone; // Return the original input if the length is not 11
  }

  // Apply the format
  return cleanedPhone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
}
