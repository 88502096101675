import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { GlobalLoader } from "../../components/GlobalLoader/GlobalLoader";
import { useGetMailingQuery } from "../../data/queries/mailing.query";
import { useCrefisaWhiteLabelData } from "../../hooks/useCrefisaWhiteLabelData";
import { useThemeWhiteLabel } from "../../hooks/useThemeWhiteLabel";
import { useMailingOnBoardingService } from "../../shared/services/useMailingOnBoardingService";
import AgreementStep from "./AgreementStep";
import ClientDataStep from "./ClientDataStep";
import ContractStep from "./ContractStep";
import DigitalSignatureStep from "./DigitalSignatureStep";
import DocumentTypeStep from "./DocumentTypeStep";
import FinishStep from "./FinishStep";
import { EMailingStepsEnum } from "./index.types";
import InstructionsStep, {
  InstructionStepVariantsEnum,
} from "./InstructionsStep";
import ReadContractStep from "./ReadContractStep";
import { OnboardingContainer, OnboardingHeader } from "./styles";
import TakePhotoStep from "./TakePhotoStep";
import VideoRecordInstructionsStep from "./VideoRecordInstructionsStep";
import VideoRecordStep from "./VideoRecordStep";
import WelcomeStep from "./WelcomeStep";

const PublicOnboarding = () => {
  const { id } = useParams();
  const theme = useThemeWhiteLabel();
  // const [isLoading, setIsLoading] = useState(false);
  const [photoToEdit, setPhotoToEdit] = useState<"front" | "back" | undefined>(
    undefined
  );
  // const [mailingData, setMailingData] = useState<MailingResponseData>(null);
  const [activeStep, setActiveStep] = useState(EMailingStepsEnum.Welcome);
  const [documentType, setDocumentType] =
    useState<InstructionStepVariantsEnum | null>(null);
  const { data: mailingData, isLoading } = useGetMailingQuery(id);
  const { putMailingStep } = useMailingOnBoardingService();
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  // const { whitelabelLogo, whitelabelName } = useWhitelabel();
  const { logo, name } = useCrefisaWhiteLabelData();

  useEffect(() => {
    if (activeStep === 0) return;
    putMailingStep(id, activeStep);
  }, [activeStep, id]);

  useEffect(() => {
    if (!mailingData?.step || !isInitialLoading) return;
    console.log('checking allow data sharing issue...')
    console.log('mailingData?.step', mailingData?.step)
    console.log('isInitialLoading', isInitialLoading)
    setActiveStep(mailingData?.step);
    setIsInitialLoading(false);
  }, [mailingData?.step]);

  const steps = useMemo(
    () => ({
      [EMailingStepsEnum.Welcome]: (
        <WelcomeStep
          setActiveStep={setActiveStep}
          isValid={!!mailingData?.MailingData}
        />
      ),
      [EMailingStepsEnum.DataCollectionAuthorization]: (
        <AgreementStep setActiveStep={setActiveStep} />
      ),
      [EMailingStepsEnum.DocumentChooseType]: (
        <DocumentTypeStep
          setActiveStep={setActiveStep}
          documentType={documentType}
          onChangeDocumentType={setDocumentType}
        />
      ),
      [EMailingStepsEnum.DocumentInstructions]: (
        <InstructionsStep
          variant={documentType}
          setActiveStep={setActiveStep}
        />
      ),
      [EMailingStepsEnum.DocumentUpload]: (
        <TakePhotoStep
          variant={documentType}
          setActiveStep={setActiveStep}
          photoToEdit={photoToEdit}
        />
      ),
      [EMailingStepsEnum.VideoInstructions]: (
        <VideoRecordInstructionsStep setActiveStep={setActiveStep} />
      ),
      [EMailingStepsEnum.VideoRecording]: (
        <VideoRecordStep setActiveStep={setActiveStep} />
      ),
      [EMailingStepsEnum.PersonalDataForm]: (
        <ClientDataStep
          setActiveStep={setActiveStep}
          setPhotoToEdit={setPhotoToEdit}
        />
      ),
      [EMailingStepsEnum.ContractReading]: (
        <ContractStep setActiveStep={setActiveStep} />
      ),
      [EMailingStepsEnum.ContractSignature]: (
        <DigitalSignatureStep setActiveStep={setActiveStep} />
      ),
      [EMailingStepsEnum.ContractDataForm]: (
        <ReadContractStep setActiveStep={setActiveStep} />
      ),
      [EMailingStepsEnum.SentConfirmation]: <FinishStep />,
    }),
    [documentType, setActiveStep, setDocumentType, mailingData, photoToEdit]
  );

  if (isLoading)
    return (
      <GlobalLoader
        logo={logo}
        alt={`${name}-logo`}
        color={theme.primaryColor}
      />
    );

  return (
    <>
      <OnboardingHeader>
        <img src={logo} alt={`${name}-logo`} />
      </OnboardingHeader>
      <OnboardingContainer>{steps[activeStep]}</OnboardingContainer>
    </>
  );
};

export default PublicOnboarding;
