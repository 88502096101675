import { Grid, Stack } from "@mui/material";
import { MailingContractDataProps } from "..";
import { formatCPF, formatNumeroBeneficio } from "../../../utils";
import MailingContractCheckbox from "../../ContractCheckbox";
import { ContractSectionTitle, ContractText } from "../styles";

const IdentificationProofContractSection = ({
  mailingData,
}: MailingContractDataProps) => {
  return (
    <>
      <Grid item xs={12}>
        <ContractSectionTitle>
          VI - COMPROVANTE DE IDENTIFICAÇÃO
        </ContractSectionTitle>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" gap="16px" alignItems="center">
          <ContractText>Responsável:</ContractText>
          <MailingContractCheckbox label="Titular" checked />
          <MailingContractCheckbox label="Reprentante Legal" />
          <MailingContractCheckbox label="Procurador" />
          <MailingContractCheckbox label="Terceiro" />
        </Stack>
        <Stack gap="4px">
          <ContractText>
            NB: {formatNumeroBeneficio(mailingData?.numeroBeneficio)}
          </ContractText>
          <ContractText>CPF: {formatCPF(mailingData?.cpf)}</ContractText>
          <ContractText>
            Ponto Atendimento Ativação: __________________________ Nº Cartão
            Magnético/Conta Corrente: {mailingData?.numConta}
            <br />
            Ponto de Atendimento Origem: {mailingData?.storeDescription}
            <br />
            Declaro que as informações acima estão corretas e são de minha
            inteira responsabilidade, ficando excluída qualquer responsabilidade
            da Crefisa, perante a lei.
          </ContractText>
        </Stack>
      </Grid>
    </>
  );
};

export default IdentificationProofContractSection;
