import { PhotoCamera } from "@mui/icons-material";
import { Button, Grid, Stack } from "@mui/material";
import { PropsWithChildren, ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetMailingQuery } from "../../../data/queries/mailing.query";
import { useThemeWhiteLabel } from "../../../hooks/useThemeWhiteLabel";
import CnhPhoto from "../../../shared/assets/cnh-example-lg.png";
import PassportPhoto from "../../../shared/assets/passport-example-lg.png";
import RgBack from "../../../shared/assets/rg-back-example-lg.png";
import RgFront from "../../../shared/assets/rg-front-example-lg.png";
import ContainerContent from "../components/ContainerContent";
import { EMailingStepsEnum, MailingWithDocVariantProps } from "../index.types";
import {
  BoldTypography,
  DocPreviewImg,
  MailingTextTypography,
  SingleFileDocPreviewImg,
  SubTitleInterTypography,
  TitleTypography,
} from "../styles";
import { getDocVariant } from "../utils";

export enum InstructionStepVariantsEnum {
  rg = "RG",
  cnh = "CNH",
  outro = "Outro",
}

const SingleFileInstructionsContent = ({
  variant,
  children,
}: {
  variant: Omit<InstructionStepVariantsEnum, "rg">;
} & PropsWithChildren) => {
  const theme = useThemeWhiteLabel();

  const textsByDocumentType: Partial<
    Record<
      InstructionStepVariantsEnum,
      { title: ReactNode; description: ReactNode; image: string }
    >
  > = {
    [InstructionStepVariantsEnum.cnh]: {
      title: (
        <SubTitleInterTypography maxWidth={604} marginBottom="24px">
          Utilize um documento de CNH físico (documentos digitais não são
          permitidos).
        </SubTitleInterTypography>
      ),
      description: (
        <SubTitleInterTypography maxWidth={604}>
          Você deverá abrir a carteira de habilitação e tirar uma foto única
          conforme o exemplo ao lado:
        </SubTitleInterTypography>
      ),
      image: CnhPhoto,
    },
    [InstructionStepVariantsEnum.outro]: {
      title: (
        <SubTitleInterTypography maxWidth={604} marginBottom="24px">
          Serão aceitos os documentos: RNE, Carteira de Conselhos Regionais,
          documentos de Classe e CTPS. Utilize um documento{" "}
          <BoldTypography>
            físico (documentos digitais não são permitidos)
          </BoldTypography>
          .{" "}
        </SubTitleInterTypography>
      ),
      description: (
        <SubTitleInterTypography maxWidth={604}>
          Você deverá abrir o documento e tirar uma foto única da parte do
          documento onde constam as informações pessoais e a foto de
          identificação, semelhante ao exemplo ao lado:
        </SubTitleInterTypography>
      ),
      image: PassportPhoto,
    },
  };

  return (
    <>
      <Grid item xs={12} sm={7}>
        <Stack height="100%" justifyContent="center">
          <TitleTypography color={theme.primaryColor}>
            Instruções:
          </TitleTypography>

          {textsByDocumentType[variant as InstructionStepVariantsEnum]?.title}

          {
            textsByDocumentType[variant as InstructionStepVariantsEnum]
              ?.description
          }
        </Stack>
      </Grid>
      <Grid item xs={12} sm={5}>
        <Stack alignItems="center" mt={{ xs: "24px", sm: "0px" }}>
          <SingleFileDocPreviewImg
            src={
              textsByDocumentType[variant as InstructionStepVariantsEnum]?.image
            }
            alt="foto do documento"
          />
        </Stack>
      </Grid>
      <Grid item xs={12} mt="32px">
        <SubTitleInterTypography textAlign="center">
          Clique no botão
          <BoldTypography> “Tirar foto do documento” </BoldTypography> abaixo,
          encaixe o documento na área sinalizada.
        </SubTitleInterTypography>
        {children}
      </Grid>
    </>
  );
};

const InstructionsStep = ({
  variant,
  setActiveStep,
}: MailingWithDocVariantProps) => {
  const { id } = useParams();
  const theme = useThemeWhiteLabel();
  const { data: mailingData } = useGetMailingQuery(id);
  const [docVariant, setDocVariant] =
    useState<InstructionStepVariantsEnum | null>(null);

  useEffect(() => {
    if (!mailingData?.additionalInfo?.documentType && !variant) return;
    setDocVariant(
      variant || getDocVariant(mailingData?.additionalInfo?.documentType)
    );
  }, [mailingData?.additionalInfo?.documentType, variant]);

  useEffect(() => {
    if (
      !!mailingData &&
      !mailingData?.additionalInfo?.documentType &&
      !variant
    ) {
      setActiveStep(EMailingStepsEnum.DocumentChooseType);
    }
  }, [mailingData, variant]);

  const buttonsContent = (
    <Stack
      direction={{ xs: "column-reverse", sm: "row" }}
      justifyContent="center"
      gap="24px"
      mt="48px"
    >
      <Button
        color="primary"
        variant="outlined"
        size="large"
        onClick={() => setActiveStep(EMailingStepsEnum.DocumentChooseType)}
      >
        Voltar
      </Button>
      <Button
        color="primary"
        variant="contained"
        size="large"
        sx={{ paddingInline: "24px" }}
        startIcon={<PhotoCamera sx={{ color: theme.alternativeColor }} />}
        onClick={() => setActiveStep(EMailingStepsEnum.DocumentUpload)}
      >
        {variant === InstructionStepVariantsEnum.rg
          ? "Tirar foto da frente"
          : "Tirar foto do documento"}
      </Button>
    </Stack>
  );

  if (!docVariant) return;

  return (
    <ContainerContent>
      <Grid container height="100%">
        {docVariant === InstructionStepVariantsEnum.rg ? (
          <>
            <Grid item xs={12} pb={{ xs: "16px", sm: "48px" }}>
              <TitleTypography color={theme.primaryColor}>
                Instruções:
              </TitleTypography>
              <SubTitleInterTypography maxWidth={841}>
                Utilize um documento de RG físico (documentos digitais não são
                permitidos).
                <br />
                <BoldTypography>
                  Primeiro você deverá tirar a foto da frente do documento
                </BoldTypography>
                &nbsp;e, em seguida, do verso.
              </SubTitleInterTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack alignItems="center" px="16px">
                <MailingTextTypography marginBottom="16px">
                  A frente do documento contém a foto do portador.
                </MailingTextTypography>
                <DocPreviewImg src={RgFront} alt="rg frente" />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack alignItems="center" px="16px">
                <MailingTextTypography
                  marginBottom="16px"
                  marginTop={{ xs: "32px", sm: "0px" }}
                >
                  O verso do documento contém os dados pessoais do portador.
                </MailingTextTypography>
                <DocPreviewImg src={RgBack} alt="rg verso" />
              </Stack>
            </Grid>
            <Grid item xs={12} pt={{ xs: "32px", sm: "64px" }}>
              <SubTitleInterTypography textAlign="center">
                Clique no botão
                <BoldTypography> “Tirar foto da frente” </BoldTypography>
                abaixo e encaixe o documento na área sinalizada.
              </SubTitleInterTypography>
              {buttonsContent}
            </Grid>
          </>
        ) : (
          <SingleFileInstructionsContent variant={docVariant}>
            {buttonsContent}
          </SingleFileInstructionsContent>
        )}
      </Grid>
    </ContainerContent>
  );
};

export default InstructionsStep;
