import { Box, Checkbox, Divider, Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const ContractContainer = styled.div`
  border: 1px solid #cad7f0;
  background-color: #fff;
  width: 100%;
  padding-top: 28px;
  position: relative;
`;

export const ContractCard = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 24px;
  margin-bottom: 24px;
  height: 1756px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContractLoading = styled(Box)`
  position: fixed;
  inset: 0px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

export const ContractLogo = styled.img`
  position: absolute;
  width: 158px;
  height: auto;
  left: 24px;
  top: 12px;
`;

export const TitleTypography = styled(Typography)`
  && {
    font-family: "Inter Bold";
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
  }
`;

export const ContractCheckbox = styled(Checkbox)`
  && {
    svg {
      background: #000000;

      path {
        fill: #ffffff;
      }
    }
  }
`;

export const ContractCheckboxLabel = styled.label`
  font-family: "Inter Regular";
  margin-left: 4px;
  font-size: 14px;
  line-height: 34px;
  letter-spacing: 0.5px;
`;

export const ContractSectionTitle = styled.div`
  font-family: "Inter Bold";
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.4px;
  background-color: #d8d8d8;
  padding-block: 11px;
  padding-inline: 24px;
  margin-block: -11px;
  margin-inline: -24px;
`;

export const ContractGrid = styled(Grid)`
  && {
    border: 1px solid #cad7f0;

    > div {
      border: 1px solid #cad7f0;
      padding-block: 11px;
      padding-inline: 24px;
    }
  }
`;

export const ContractText = styled.div`
  && {
    font-family: "Inter Regular";
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #000000;
  }
`;

export const ContractSmallText = styled.div`
  && {
    font-family: "Inter Regular";
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.4px;
    color: #000000;
  }
`;

export const ContractDrawArea = styled.div`
  && {
    height: 362px;
    border-radius: 10px;
    padding: 4px 4px 32px 4px;
    box-shadow: 0px 2px 10px 0px #24242433;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
`;

export const ContractDrawSignatureLine = styled.div`
  && {
    height: 2px;
    width: 90%;
    margin: 0 auto;
    background-color: #000;
    position: relative;
    top: -64px;
  }
`;

export const ContractDivider = styled(Divider)`
  && {
    border-bottom-width: 2px;
    border-color: #000000;
    width: 100%;
  }
`;

export const SmallContractText = styled.div`
   {
    font-family: "Inter Regular";
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #000000;
  }
`;

export const BoldContractText = styled(Box)`
  && {
    font-family: "Inter Bold";
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.4px;
    font-weight: 800;
    color: #000000;
  }
`;

export const ContractTable = styled.table`
  && {
    border: 1px solid #1e1e1e;
    width: 100%;
    border-collapse: collapse;
  }
`;

export const ContractTh = styled.th`
  && {
    border: 1px solid #000000;
    background-color: #005480;
    color: white;
    font-family: "Inter Bold";
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: center;
    padding: 8px;
  }
`;

export const ContractTd = styled.td`
  && {
    border: 1px solid #000000;
    font-family: "Inter Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #000000;
    padding: 8px;

    &:not(:first-of-type) {
      text-align: center;
    }
  }
`;

export const BlankStripContainer = styled.div`
  && {
    height: 100%;
    background-color: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const BlankStripText = styled.div`
  && {
    background-color: white;
    border: 1px solid #000;
    text-align: center;
    position: absolute;
  }
`;
