import { Button } from "@mui/material";
import React from "react";
import Typography from "../../../components/Typography";
import useWhitelabel from "../../../hooks/useWhitelabel";
import { PayrollLoanOnboardingResponse } from "../../../shared/models/responses/payroll-loan-onboarding-response";
import { StyledDivText } from "../../onBoardingVideo/onBoardingVideo/styles";
import { StyledDivButton } from "../onBoardingVideo/styles";

interface introductionPayRollVideoProps {
  data: PayrollLoanOnboardingResponse | null;
  navigateToNext: () => void;
}

const IntroductionPayRoll: React.FC<introductionPayRollVideoProps> = ({
  data,
  navigateToNext,
}) => {
  const { whitelabelName } = useWhitelabel();
  return (
    <>
      <Typography variant="h1" color="green">
        Boas vindas ao {whitelabelName}!
      </Typography>
      <Typography variant="caption">Acesso de visitante.</Typography>
      <StyledDivText>
        <Typography variant="caption">
          Olá! Você recebeu este vídeo pois contratou algum serviço do banco{" "}
          {data?.inviteDTO.bankName}
        </Typography>
      </StyledDivText>
      <Typography variant="caption5" align="center">
        LEIA ATENTAMENTE AS INSTRUÇÕES ABAIXO ANTES DE INICIAR!
      </Typography>
      {data?.inviteDTO.legalRepresentative &&
      data?.inviteDTO.legalRepresentative.name ? (
        <Typography variant="caption">
          1. O primeiro passo consiste no envio das fotos de um documento
          pertencente ao/a representante legal{" "}
          {data?.inviteDTO.legalRepresentative.name}
          do beneficiário/a {data?.inviteDTO.beneficiary.name}.
        </Typography>
      ) : (
        <Typography variant="caption">
          1. O primeiro passo consiste no envio das fotos de um documento
          pertencente ao beneficiário/a {data?.inviteDTO.beneficiary.name}.
        </Typography>
      )}
      <Typography variant="caption">
        2. Em seguida você deverá gravar um vídeo lendo o texto-guia exibido na
        tela. O vídeo deverá conter SOMENTE as palavras contidas no texto.
        Clique no botão “Iniciar gravação”, leia o texto com calma e, em
        seguida, clique em “Finalizar Gravação” para concluir. Não se preocupe
        caso erre, você poderá reenviar outro video.
      </Typography>
      <StyledDivButton>
        <Button variant={"contained"} color="primary" onClick={navigateToNext}>
          {"Próximo"}
        </Button>
      </StyledDivButton>
    </>
  );
};

export default IntroductionPayRoll;
