import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Property } from "csstype";
import React, { useState } from "react";
import { FormatUtils } from "../../shared/utils/format-utils";
import { applyMask, removeMask } from "../../shared/utils/remove-mask";
import "./Input.css";

interface InputProps {
  id?: string;
  placeholder?: string;
  label?: string;
  value?: string;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  isPassword?: boolean;
  mask?: string | ((len: number) => string);
  maxLength?: number;
  isBigger?: boolean;
  isNormal?: boolean;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  multiline?: boolean;
  isCurrency?: boolean;
  validationCallback?: (value?: string) => boolean;
  error?: string;
  type?: React.InputHTMLAttributes<unknown>["type"];
  width?: "string" | Property.Width;
  startAdornment?: React.ReactNode;
  hasError?: boolean;
  pattern?: string;
  smallLabel?: boolean;
}

const Input: React.FC<InputProps> = ({ ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const isError =
    props?.hasError ||
    (props?.value &&
      props?.validationCallback &&
      !props?.validationCallback(props?.value));

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (props.isCurrency) {
      const valueNumber = parseInt(e.target.value.replace(/[^0-9]/g, ""));
      props.onChange(FormatUtils.toBRLCurrency(valueNumber / 100));
      return;
    }
    const mask =
      typeof props.mask === "string"
        ? props.mask
        : props.mask?.(e.target.value.length);
    const inputValue = props.mask ? removeMask(e.target.value) : e.target.value;
    props.onChange(applyMask(inputValue, mask));
  };
  const getSize = () => {
    if (props.isBigger) return "medium";
    if (props.isNormal) return undefined;
    return "small";
  };

  const size = getSize();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <TextField
        multiline={props?.multiline}
        size={size}
        fullWidth={props?.fullWidth}
        style={{ width: props?.width, backgroundColor: "white" }}
        disabled={props?.disabled}
        id={props?.id}
        label={props?.label || props?.placeholder}
        placeholder={props?.placeholder || props?.label}
        required={props?.required}
        error={!!isError}
        helperText={isError && props?.error}
        value={props?.value}
        InputLabelProps={{
          shrink: !!props?.value,
          sx: { fontSize: props.smallLabel ? "0.8rem" : "1rem" },
        }}
        onChange={onChange}
        inputProps={{ maxLength: props?.maxLength }}
        onBlur={(e) => props.onBlur?.(e.target.value)}
        type={props?.isPassword && !showPassword ? "password" : props?.type}
        variant="outlined"
        InputProps={{
          style: { backgroundColor: "white" },
          endAdornment: (
            <>
              {props.startAdornment && (
                <InputAdornment position="end">
                  {props.startAdornment}
                </InputAdornment>
              )}
              {props.isPassword && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
            </>
          ),
        }}
      />
    </>
  );
};

export default Input;
