import { Box, Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import { domToPng } from "modern-screenshot";
import { createRef, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { StyledCircularProgress } from "../../../components/Loading/styles";
import { useResponsiveZoom } from "../../../hooks/useResponsiveZoom";
import { MailingResponseData } from "../../../shared/models/backend/mailing/mailing";
import { FileTypeEnum } from "../../../shared/models/enums/files.enum";
import useMailingOnBoardingService from "../../../shared/services/useMailingOnBoardingService";
import MailingContract from "../components/Contract";
import { ContractLoading } from "../components/Contract/styles";
import { EMailingStepsEnum, MailingStepProps } from "../index.types";
import { BoldTypography, SubTitleInterTypography } from "../styles";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const ContractStep = ({ setActiveStep }: MailingStepProps) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadingContract, setIsUploadingContract] = useState(false);
  const contractPages = useMemo(() => 6, []);
  const [mailingData, setMailingData] = useState<MailingResponseData>(null);
  const { sendMailingFile, getMailing } = useMailingOnBoardingService();
  const refs = useRef<any>(
    Array.from({ length: contractPages }, () => createRef())
  );

  useEffect(() => {
    if (!id) return;
    setIsLoading(true);
    getMailing(id)
      .then((response) => {
        setMailingData(response);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      });
  }, [id]);

  const handleUploadContract = async () => {
    setIsUploadingContract(true);

    await delay(2000);

    try {
      const blobPromises = refs.current.map(async (ref, index) => {
        if (ref.current) {
          const dataUrl = await domToPng(ref.current);

          const response = sendMailingFile({
            publicId: id,
            fileType: FileTypeEnum.Contract,
            fileName: `${index + 1}.png`,
            fileContent: dataUrl,
          });
          return response;
        }
      });

      const results = await Promise.all(blobPromises);
      setIsUploadingContract(false);
      if (results) {
        setActiveStep(EMailingStepsEnum.ContractSignature);
      }
    } catch (error) {
      setIsUploadingContract(false);
      console.error("Error generating or uploading images:", error);
    }
  };

  useEffect(() => {
    const handleKeydownBlur = (event) => {
      if (event.key == "PrintScreen") {
        event.preventDefault();
        document.getElementById("mailing-contract").style.filter = "blur(5px)";
      }
    };
    const handleWindowBlur = () => {
      document.getElementById("mailing-contract").style.filter = "blur(5px)";
    };
    const handleWindowFocus = () => {
      document.getElementById("mailing-contract").style.filter = "none";
    };
    window.addEventListener("blur", handleWindowBlur);
    window.addEventListener("focus", handleWindowFocus);
    document.addEventListener("keydown", handleKeydownBlur);
    return () => {
      window.removeEventListener("blur", handleWindowBlur);
      window.removeEventListener("focus", handleWindowFocus);
      document.removeEventListener("keydown", handleKeydownBlur);
    };
  }, []);

  const minWidth = 1200;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const widthOffset = minWidth - (isSmallScreen ? 32 : 84);
  const zoom = useResponsiveZoom(minWidth, isSmallScreen ? 32 : 84);
  const containerRef = useRef<HTMLDivElement>(null);
  const [scaledHeight, setScaledHeight] = useState<number | undefined>();

  const calculateHeight = () => {
    if (containerRef.current) {
      const contentHeight = containerRef.current.scrollHeight;
      setScaledHeight(contentHeight * zoom);
    }
  };

  useEffect(() => {
    calculateHeight();

    const timeout = setTimeout(calculateHeight, 1000);

    return () => clearTimeout(timeout);
  }, [zoom]);

  return (
    <Stack>
      {isUploadingContract && (
        <ContractLoading>
          <StyledCircularProgress size={48} />
        </ContractLoading>
      )}
      <Box
        ref={containerRef}
        id="mailing-contract"
        sx={
          isUploadingContract
            ? {
                minWidth: "1200px",
                maxWidth: "1200px",
                height: scaledHeight ? `${scaledHeight}px` : "auto",
              }
            : {
                width: "100%",
                minWidth: `${widthOffset}px`,
                maxWidth: "1200px",
                height: scaledHeight ? `${scaledHeight}px` : "auto",
                transform: `scale(${zoom})`,
                transformOrigin: "0 0",
                margin: "0 auto",
              }
        }
        paddingBlock={{ xs: "24px", sm: "32px" }}
      >
        {Array.from({ length: contractPages }, (_, index) => (
          <div key={index} ref={refs.current[index]}>
            <MailingContract
              page={index + 1}
              mailingData={mailingData?.MailingData}
              userData={mailingData?.MailingUserData}
            />
          </div>
        ))}
      </Box>

      <SubTitleInterTypography textAlign="center" marginTop="32px">
        Após revisar os dados, clique no botão{" "}
        <BoldTypography>“Assinar formulário”.</BoldTypography>
      </SubTitleInterTypography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="center"
        gap="24px"
        mt="48px"
        mb="24px"
      >
        <Button
          color="primary"
          variant="outlined"
          size="large"
          disabled={isLoading || isUploadingContract}
          onClick={() => setActiveStep(EMailingStepsEnum.PersonalDataForm)}
        >
          Voltar
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading || isUploadingContract}
          sx={{ paddingInline: "24px" }}
          startIcon={
            isUploadingContract ? (
              <StyledCircularProgress size={16} />
            ) : undefined
          }
          onClick={handleUploadContract}
        >
          Assinar formulário
        </Button>
      </Stack>
    </Stack>
  );
};

export default ContractStep;
