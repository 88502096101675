import { Grid } from "@mui/material";
import MailingContractCheckbox from "../../ContractCheckbox";
import { ContractSectionTitle, ContractText } from "../styles";

const ReceiptContractSection = () => {
  return (
    <>
      <Grid item xs={12}>
        <ContractSectionTitle>VII - RECIBO</ContractSectionTitle>
      </Grid>
      <Grid item xs={12}>
        <ContractText>
          Desejo receber a cópia do presente Termo de Adesão, da seguinte forma:
        </ContractText>

        <MailingContractCheckbox
          label="Recebimento através da Impressão do respectivo Documento, que declaro receber neste ato;"
        />
        <MailingContractCheckbox label="O cliente poderá solicitar uma cópia do Documento nos canais disponíveis. " />
      </Grid>
    </>
  );
};

export default ReceiptContractSection;
