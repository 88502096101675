import { Box, Button, Stack } from "@mui/material";
import { useThemeWhiteLabel } from "../../../hooks/useThemeWhiteLabel";
import ContainerContent from "../components/ContainerContent";
import {
  BoldPoppinsTypography,
  OnboardingCard,
  SubTitleTypography,
  TitleTypography,
} from "../styles";

const FinishStep = () => {
  const theme = useThemeWhiteLabel();

  return (
    <ContainerContent centered>
      <OnboardingCard sx={{ maxWidth: 854 }}>
        <Box maxWidth={503}>
          <TitleTypography
            variant="h1"
            color={theme.primaryColor}
            style={{ lineHeight: "32px" }}
            align="center"
          >
            Parabéns, você finalizou todos os passos!
            <br />
            Bem-vindo à Crefisa!
          </TitleTypography>
          <SubTitleTypography variant="h2" align="center" mb="40px">
            Sua pré- ativação foi finalizada.
            <br />
            Para garantir o recebimento de seu benefício, você deverá comparecer
            em nosso Posto de Atendimento Crefisa mais próximo para concluir o
            processo e retirar seu cartão. <br />
            <BoldPoppinsTypography>
              Atenção! Este passo é fundamental e foi desenvolvido pensando na
              sua comodidade e segurança.
            </BoldPoppinsTypography>
          </SubTitleTypography>
          <SubTitleTypography
            textAlign="center"
            color={theme.primaryColor}
            pt="24px"
          >
            Aproveite para conhecer as ofertas que preparamos especialmente para
            você!
          </SubTitleTypography>
        </Box>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="center"
          gap="24px"
          mt="24px"
        >
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={() => {
              window.open("https://api.whatsapp.com/send?phone=5511988060603");
            }}
          >
            OK
          </Button>
        </Stack>
      </OnboardingCard>
    </ContainerContent>
  );
};

export default FinishStep;
