import { Grid, Stack } from "@mui/material";
import { MailingContractDataProps } from "..";
import { formatNumeroBeneficio } from "../../../utils";
import MailingContractCheckbox from "../../ContractCheckbox";
import { ContractSectionTitle, ContractText } from "../styles";

const MagneticCardContractSection = ({
  mailingData,
}: MailingContractDataProps) => {
  return (
    <>
      <Grid item xs={12}>
        <ContractSectionTitle>II - CARTÃO MAGNÉTICO</ContractSectionTitle>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" gap="16px">
          <MailingContractCheckbox
            label={`Definitivo NB: ${formatNumeroBeneficio(
              mailingData?.numeroBeneficio
            )}`}
            checked
          />
        </Stack>
        <ContractText>
          Declaro ter lido previamente os termos e condições que estão na fl. 03 do presente Termo e que não tenho dúvidas sobre quaisquer de suas cláusulas e condições, aceitando-os, assim, na integralidade.
        </ContractText>
      </Grid>
    </>
  );
};

export default MagneticCardContractSection;
