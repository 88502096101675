import { Grid } from "@mui/material";
import { ContractSectionTitle, ContractText } from "../styles";

const TransferAuthorizationContractSection = () => {
  return (
    <>
      <Grid item xs={12}>
        <ContractSectionTitle>
          V - AUTORIZAÇÃO DE TRANSFERÊNCIA DOS CRÉDITOS DOS BENEFÍCIOS DO INSS
          PARA CONTA CORRENTE
           {/* - NÃO SE APLICA */}
        </ContractSectionTitle>
      </Grid>
      <Grid item xs={12}>
        <ContractText>
          Filial: _____________________________________________________ Conta
          corrente: _____________________ NB: __________________________ Desejo
          receber o crédito relativo ao meu benefício do INSS por meio da conta
          de minha titularidade acima indicada. Quaisquer valores existentes no
          Cartão Magnético ou Conta Corrente apenas e tão somente estarão
          disponíveis após o processamento dos débitos autorizados das parcelas
          de eventuais empréstimos contratados com a Crefisa S/A Crédito,
          Financiamento e Investimentos, CNPJ nº 60.779.196/0001-96. Autorizo o
          Banco Crefisa S/A a proceder, na conta acima indicada, ao estorno das
          importâncias creditadas indevidamente e revertê-las ao INSS, conforme
          solicitação desse Órgão.
        </ContractText>
      </Grid>
    </>
  );
};

export default TransferAuthorizationContractSection;
