import { Button, Stack } from "@mui/material";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { StyledCircularProgress } from "../../../components/Loading/styles";
import { PdfViewer } from "../../../components/PDFViewer/PDFViewer";
import useMailingOnBoardingService from "../../../shared/services/useMailingOnBoardingService";
import ContainerContent from "../components/ContainerContent";
import { EMailingStepsEnum, MailingStepProps } from "../index.types";

const ReadContractStep = ({ setActiveStep }: MailingStepProps) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [pdf, setPdf] = useState<string | null>(null);
  const { getMailingSignedContract, finishMailingFlow } =
    useMailingOnBoardingService();

  const handleFinishMailingFlow = () => {
    setIsLoading(true);
    finishMailingFlow(id)
      .then(() => {
        setActiveStep(EMailingStepsEnum.SentConfirmation);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!id) return;
    setIsLoading(true);
    getMailingSignedContract(id)
      .then((response) => {
        setPdf(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  return (
    <Stack height={pdf ? "calc(100% - 48px)" : "auto"}>
      <ContainerContent id="mailing-contract" centered>
        {pdf ? (
          <PdfViewer pdfUrl={pdf} />
        ) : (
          // <div style={{ height: "100%", width: "100%" }}>
          //   {/* <iframe
          //     src={pdf}
          //     style={{ width: "100%", height: "100%", border: "none" }}
          //     title="PDF"
          //   ></iframe> */}
          // </div>
          <Stack
            width="100%"
            height="300px"
            alignItems="center"
            justifyContent="center"
          >
            <StyledCircularProgress size={40} />
          </Stack>
        )}
      </ContainerContent>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="center"
        gap="24px"
        mt="8px"
      >
        <Button
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          sx={{ paddingInline: "24px" }}
          startIcon={
            isLoading ? <StyledCircularProgress size={16} /> : undefined
          }
          onClick={handleFinishMailingFlow}
        >
          Concluir
        </Button>
      </Stack>
    </Stack>
  );
};

export default ReadContractStep;
