export const issuingBodysList = [
  { value: "SEM INFORMAÇÃO", label: "SEM INFORMAÇÃO" },
  {
    value: "SECRETARIA DE SEGURANCA PUBLICA",
    label: "SECRETARIA DE SEGURANCA PUBLICA",
  },
  { value: "MINISTERIO DA AERONAUTICA", label: "MINISTERIO DA AERONAUTICA" },
  { value: "MINISTERIO DO EXERCITO", label: "MINISTERIO DO EXERCITO" },
  { value: "MINISTERIO DA MARINHA", label: "MINISTERIO DA MARINHA" },
  { value: "POLICIA FEDERAL", label: "POLICIA FEDERAL" },
  {
    value: "CONSELHO REGIONAL DE ADMINISTRACAO",
    label: "CONSELHO REGIONAL DE ADMINISTRACAO",
  },
  {
    value: "CONSELHO REGIONAL DE ASSISTENCIA SOCIAL",
    label: "CONSELHO REGIONAL DE ASSISTENCIA SOCIAL",
  },
  {
    value: "CONSELHO REGIONAL DE BIBLIOTECONOMIA",
    label: "CONSELHO REGIONAL DE BIBLIOTECONOMIA",
  },
  {
    value: "CONSELHO REGIONAL DE CONTABILIDADE",
    label: "CONSELHO REGIONAL DE CONTABILIDADE",
  },
  {
    value: "CONSELHO REGIONAL DE CORRETORES IMOVEIS",
    label: "CONSELHO REGIONAL DE CORRETORES IMOVEIS",
  },
  {
    value: "CONSELHO REGIONAL DE ECONOMIA",
    label: "CONSELHO REGIONAL DE ECONOMIA",
  },
  {
    value: "CONSELHO REGIONAL DE ENFERMAGEM",
    label: "CONSELHO REGIONAL DE ENFERMAGEM",
  },
  { value: "CREA", label: "CREA" },
  {
    value: "CONSELHO REGIONAL DE ESTATISTICA",
    label: "CONSELHO REGIONAL DE ESTATISTICA",
  },
  {
    value: "CONSELHO REGIONAL DE FARMACIA",
    label: "CONSELHO REGIONAL DE FARMACIA",
  },
  { value: "CREFITO", label: "CREFITO" },
  {
    value: "CONSELHO REGIONAL DE MEDICINA",
    label: "CONSELHO REGIONAL DE MEDICINA",
  },
  { value: "CRMV", label: "CRMV" },
  {
    value: "ORDEM DOS MUSICOS DO BRASIL",
    label: "ORDEM DOS MUSICOS DO BRASIL",
  },
  {
    value: "CONSELHO REGIONAL DE NUTRICAO",
    label: "CONSELHO REGIONAL DE NUTRICAO",
  },
  {
    value: "CONSELHO REGIONAL DE ODONTOLOGIA",
    label: "CONSELHO REGIONAL DE ODONTOLOGIA",
  },
  { value: "CONRERP", label: "CONRERP" },
  {
    value: "CONSELHO REGIONAL DE PSICOLOGIA",
    label: "CONSELHO REGIONAL DE PSICOLOGIA",
  },
  {
    value: "CONSELHO REGIONAL DE QUIMICA",
    label: "CONSELHO REGIONAL DE QUIMICA",
  },
  { value: "CORE", label: "CORE" },
  {
    value: "ORDEM DOS ADVOGADOS DO BRASIL",
    label: "ORDEM DOS ADVOGADOS DO BRASIL",
  },
  {
    value: "CONSELHO REGIONAL DE BIOLOGIA - CRB",
    label: "CONSELHO REGIONAL DE BIOLOGIA - CRB",
  },
  {
    value: "CONSELHO REGIONAL DE FONAUDIOLOGIA - CRFA",
    label: "CONSELHO REGIONAL DE FONAUDIOLOGIA - CRFA",
  },
  {
    value: "CORESS-CONSELHO REGIONAL SERVICO SOCIAL",
    label: "CORESS-CONSELHO REGIONAL SERVICO SOCIAL",
  },
  {
    value: "CRTR-CONSELHO REG TECNICOS EM RADIOLOGIA",
    label: "CRTR-CONSELHO REG TECNICOS EM RADIOLOGIA",
  },
  {
    value: "DETRAN - DEPARTAMENTO DE TRANSITO",
    label: "DETRAN - DEPARTAMENTO DE TRANSITO",
  },
  { value: "PM - POLICIA MILITAR", label: "PM - POLICIA MILITAR" },
  { value: "CORPO DE BOMBEIROS", label: "CORPO DE BOMBEIROS" },
  {
    value: "MIBA-MEMBRO INST.BRASILEIRO DE ATUARIOS",
    label: "MIBA-MEMBRO INST.BRASILEIRO DE ATUARIOS",
  },
  {
    value: "SEC COORD EST DA JUSTICA E DEFESA SOCIAL",
    label: "SEC COORD EST DA JUSTICA E DEFESA SOCIAL",
  },
  {
    value: "SEC COORD MUNICIPAL DA DEFESA SOCIAL",
    label: "SEC COORD MUNICIPAL DA DEFESA SOCIAL",
  },
  {
    value: "DOC EXP NO EXTERIOR CONSTA APENAS CADPF",
    label: "DOC EXP NO EXTERIOR CONSTA APENAS CADPF",
  },
  { value: "OUTROS", label: "OUTROS" },
];
