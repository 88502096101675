import { Autocomplete, TextField } from "@mui/material";

export type SearchableSelectProps = {
  label: string;
  options: { label: string; value: string }[];
  value: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
  error?: string;
  hasError?: boolean;
  labelAsValue?: boolean;
};

export default function SearchableSelect({
  options,
  value,
  onChange,
  label,
  labelAsValue = false,
  error = "",
  hasError = false,
  placeholder = "Buscar...",
}: SearchableSelectProps) {
  return (
    <Autocomplete
      fullWidth
      options={options}
      getOptionLabel={(option) => option.label}
      value={
        options.find((option) =>
          labelAsValue ? option.label === value : option.value === value
        ) || null
      }
      onChange={(event, newValue) => {
        onChange(labelAsValue ? newValue?.label : newValue?.value || "");
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          placeholder={placeholder}
          error={hasError}
          helperText={error}
        />
      )}
      isOptionEqualToValue={(option, value) => option.value === value?.value}
    />
  );
}
