import { useEffect, useState } from "react";

export const useResponsiveZoom = (minWidth: number, padding?: number) => {
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    const updateZoom = () => {
      const availableWidth = window.innerWidth - padding;
      const newZoom = availableWidth < minWidth ? availableWidth / minWidth : 1;
      setZoom(newZoom);
    };

    updateZoom(); // Initialize zoom on mount
    window.addEventListener("resize", updateZoom);

    return () => window.removeEventListener("resize", updateZoom);
  }, [minWidth, padding]);

  return zoom;
};
