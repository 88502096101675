import { Grid, Stack } from "@mui/material";
import { MailingContractDataProps } from "..";
import { BoldTypography } from "../../../styles";
import MailingContractCheckbox from "../../ContractCheckbox";
import { ContractSectionTitle, ContractText } from "../styles";

const CreditCardContractSection = ({ userData }: MailingContractDataProps) => {
  return (
    <>
      <Grid item xs={12}>
        <ContractSectionTitle>III - CARTÃO DE CRÉDITO</ContractSectionTitle>
      </Grid>
      <Grid item xs={6}>
        <Stack direction="row" gap="16px" alignItems="center">
          <ContractText>Tipo:</ContractText>
          <MailingContractCheckbox label="BÁSICO" />
          <MailingContractCheckbox label="DIFERENCIADO" />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack direction="row" gap="16px" alignItems="center">
          <ContractText>Cobertura:</ContractText>
          <MailingContractCheckbox label="NACIONAL" />
          <MailingContractCheckbox
            label="INTERNACIONAL"
            checked={userData?.cardAcquired}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <ContractText>
          Declaro ter sido informado e estar ciente de todos os termos e
          condições apresentados no{" "}
          <BoldTypography>
            CONTRATO DE CARTÃO DE CRÉDITO - PESSOA FÍSICA
          </BoldTypography>
          , o qual li, recebi e está disponível também no site www.crefisa.com.br, declarando também que não tenho dúvidas sobre quaisquer de suas cláusulas e condições, aceitando-o, assim, na integralidade, ao qual solicito a minha adesão. FORMA DE PAGAMENTO DA FATURA: boleto bancário. VENCIMENTO DA FATURA:
        </ContractText>
        <Stack direction="row" gap="16px" alignItems="center">
          <MailingContractCheckbox
            label="1"
            checked={userData?.cardExpirationDate === 1}
          />
          <MailingContractCheckbox
            label="5"
            checked={userData?.cardExpirationDate === 5}
          />
          <MailingContractCheckbox
            label="10"
            checked={userData?.cardExpirationDate === 10}
          />
          <MailingContractCheckbox
            label="15"
            checked={userData?.cardExpirationDate === 15}
          />
          <MailingContractCheckbox
            label="20"
            checked={userData?.cardExpirationDate === 20}
          />
          <MailingContractCheckbox
            label="25"
            checked={userData?.cardExpirationDate === 25}
          />
        </Stack>
        <ContractText>
          Outras condições estão
          detalhadas na fl. 03 do presente Termo. 
        </ContractText>
        <Stack direction="row" gap="16px" alignItems="center" mt="4px">
          <MailingContractCheckbox
            label="Cartão de crédito não solicitado."
            checked={!userData?.cardAcquired}
          />
        </Stack>
      </Grid>
    </>
  );
};

export default CreditCardContractSection;
