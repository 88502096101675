import { Edit } from "@mui/icons-material";
import { Box, Button, Grid, Stack } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { StyledCircularProgress } from "../../../components/Loading/styles";
import { useThemeWhiteLabel } from "../../../hooks/useThemeWhiteLabel";
import {
  MailingAdditionalInfoProps,
  MailingData,
} from "../../../shared/models/backend/mailing/mailing";
import {
  documentTypeDescriptions,
  FileRequestDocumentEnum,
} from "../../../shared/models/enums/files.enum";
import ContainerContent from "../components/ContainerContent";
import { EMailingStepsEnum, MailingStepProps } from "../index.types";
import {
  Bold500Typography,
  BoldTypography,
  EditDocumentContainer,
  InterTextTypography,
  LineDecorator,
  OnboardingCardLowRadius,
  SubTitleInterTypography,
  SubTitleLargeTypography,
  TitleExtraBoldTypography,
} from "../styles";
import {
  formatBrazilianPhoneNumber,
  formatCPF,
  formatNumeroBeneficio,
} from "../utils";

type ReviewClientDataProps = {
  onBack: () => void;
  mailingData: MailingData;
  additionalInfo: MailingAdditionalInfoProps;
  userData: any;
  setPhotoToEdit: Dispatch<SetStateAction<"front" | "back">>;
} & MailingStepProps;

const ReviewClientData = ({
  onBack,
  setActiveStep,
  mailingData,
  additionalInfo,
  userData,
  setPhotoToEdit,
}: ReviewClientDataProps) => {
  const theme = useThemeWhiteLabel();
  const [isFrontImageLoaded, setIsFrontImageLoaded] = useState(false);
  const [isBackImageLoaded, setIsBackImageLoaded] = useState(false);

  const handleUpdateImage = (imageType?: "front" | "back") => {
    if (imageType && !!additionalInfo?.documentBackImageUrl) {
      console.log(imageType);
      setPhotoToEdit(imageType);
    }
    setTimeout(() => {
      setActiveStep(EMailingStepsEnum.DocumentUpload);
    }, 10);
  };

  const handleSaveAndContinue = () => {
    setActiveStep(EMailingStepsEnum.ContractReading);
  };

  return (
    <ContainerContent>
      <Grid container spacing="40px" mb="24px">
        <Grid item xs={12} md={6}>
          <OnboardingCardLowRadius sx={{ padding: "24px !important" }}>
            <LineDecorator style={{ backgroundColor: theme.primaryColor }} />
            <TitleExtraBoldTypography color={theme.primaryColor} align="center">
              Dados de Identificação
            </TitleExtraBoldTypography>
            <Stack gap="8px" width="100%">
              <InterTextTypography>
                Nome:&nbsp;
                <Bold500Typography>{mailingData?.nome}</Bold500Typography>
              </InterTextTypography>
              <InterTextTypography>
                CPF:&nbsp;
                <Bold500Typography>
                  {formatCPF(mailingData?.cpf)}
                </Bold500Typography>
              </InterTextTypography>
              <InterTextTypography>
                Número do benefício:&nbsp;
                <Bold500Typography>
                  {formatNumeroBeneficio(mailingData?.numeroBeneficio)}
                </Bold500Typography>
              </InterTextTypography>
              <InterTextTypography>
                Telefone:&nbsp;
                <Bold500Typography>
                  {formatBrazilianPhoneNumber(userData?.phone)}
                </Bold500Typography>
              </InterTextTypography>
              <InterTextTypography>
                Email:&nbsp;
                <Bold500Typography>{userData?.email}</Bold500Typography>
              </InterTextTypography>
              <InterTextTypography>
                Nome da mãe:&nbsp;
                <Bold500Typography>{userData?.nomeMae}</Bold500Typography>
              </InterTextTypography>
              <InterTextTypography>
                Nome do pai:&nbsp;
                <Bold500Typography>{userData?.nomePai}</Bold500Typography>
              </InterTextTypography>
              <InterTextTypography>
                Escolaridade:&nbsp;
                <Bold500Typography>{userData?.escolaridade}</Bold500Typography>
              </InterTextTypography>
            </Stack>

            <Box width="100%" mt="32px">
              <SubTitleLargeTypography sx={{ color: theme.primaryColor }}>
                Endereço:
              </SubTitleLargeTypography>
            </Box>
            <Stack gap="8px" width="100%">
              <InterTextTypography>
                Rua:&nbsp;
                <Bold500Typography>{userData?.logradouro}</Bold500Typography>
              </InterTextTypography>
              <InterTextTypography>
                Número:&nbsp;
                <Bold500Typography>{userData?.numero}</Bold500Typography>
              </InterTextTypography>
              <InterTextTypography>
                Complemento:&nbsp;
                <Bold500Typography>{userData?.complemento}</Bold500Typography>
              </InterTextTypography>
              <InterTextTypography>
                Bairro:&nbsp;
                <Bold500Typography>{userData?.bairro}</Bold500Typography>
              </InterTextTypography>
              <InterTextTypography>
                CEP:&nbsp;
                <Bold500Typography>{userData?.cep}</Bold500Typography>
              </InterTextTypography>
              <InterTextTypography>
                Município:&nbsp;
                <Bold500Typography>{userData?.cidade}</Bold500Typography>
              </InterTextTypography>
              <InterTextTypography>
                UF:&nbsp;
                <Bold500Typography>{userData?.ufEndereco}</Bold500Typography>
              </InterTextTypography>
            </Stack>
          </OnboardingCardLowRadius>
        </Grid>
        <Grid item xs={12} md={6}>
          <OnboardingCardLowRadius
            sx={{
              padding: "24px !important",
              height: "100%",
              justifyContent: "flex-start !important",
            }}
          >
            <LineDecorator style={{ backgroundColor: theme.primaryColor }} />
            <TitleExtraBoldTypography color={theme.primaryColor} align="center">
              Dados do documento
            </TitleExtraBoldTypography>
            <Stack gap="8px" width="100%">
              <InterTextTypography>
                Tipo de documento:&nbsp;
                <Bold500Typography>
                  {documentTypeDescriptions[additionalInfo?.documentType]}
                </Bold500Typography>
              </InterTextTypography>
              <InterTextTypography>
                Número do documento:&nbsp;
                <Bold500Typography>
                  {formatNumeroBeneficio(userData?.numeroDocumento)}
                </Bold500Typography>
              </InterTextTypography>
              <InterTextTypography>
                Órgão emissor:&nbsp;
                <Bold500Typography>{userData?.slgOrgemissor}</Bold500Typography>
              </InterTextTypography>
              <InterTextTypography>
                UF emissor:&nbsp;
                <Bold500Typography>{userData?.ufEmissor}</Bold500Typography>
              </InterTextTypography>
              <InterTextTypography>
                Data da emissão:&nbsp;
                <Bold500Typography>{userData?.dataEmissao}</Bold500Typography>
              </InterTextTypography>
            </Stack>

            <Stack
              direction={{ xs: "column", md: "row" }}
              gap={{ xs: "0px", md: "24px" }}
              justifyContent="center"
              alignItems="center"
            >
              {additionalInfo?.documentFrontImageUrl && (
                <>
                  {!isFrontImageLoaded && <StyledCircularProgress size={32} />}
                  <EditDocumentContainer
                    style={{
                      visibility: isFrontImageLoaded ? "visible" : "hidden",
                      position: isFrontImageLoaded ? "relative" : "absolute",
                    }}
                  >
                    <img
                      src={additionalInfo?.documentFrontImageUrl}
                      alt="front document image"
                      onLoad={() => setIsFrontImageLoaded(true)}
                    />
                    <button
                      style={{ backgroundColor: theme.primaryColor }}
                      onClick={() => handleUpdateImage("front")}
                    >
                      <Edit sx={{ color: "white" }} />
                    </button>
                  </EditDocumentContainer>
                </>
              )}

              {additionalInfo?.documentType === FileRequestDocumentEnum.RG && (
                <>
                  {additionalInfo?.documentBackImageUrl ? (
                    <>
                      {!isBackImageLoaded && (
                        <StyledCircularProgress size={32} />
                      )}
                      <EditDocumentContainer
                        style={{
                          visibility: isBackImageLoaded ? "visible" : "hidden",
                          position: isBackImageLoaded ? "relative" : "absolute",
                        }}
                      >
                        <img
                          src={additionalInfo?.documentBackImageUrl}
                          onLoad={() => setIsBackImageLoaded(true)}
                          alt="back document image"
                        />
                        <button
                          style={{ backgroundColor: theme.primaryColor }}
                          onClick={() => handleUpdateImage("back")}
                        >
                          <Edit sx={{ color: "white" }} />
                        </button>
                      </EditDocumentContainer>
                    </>
                  ) : (
                    <StyledCircularProgress size={32} />
                  )}
                </>
              )}
            </Stack>

            <TitleExtraBoldTypography
              color={theme.primaryColor}
              align="center"
              mt="48px"
            >
              Dados do cartão Magnético
            </TitleExtraBoldTypography>

            <Stack gap="8px" width="100%" mb="16px">
              <InterTextTypography>
                Cartão magnético:
                <Bold500Typography> Definitivo</Bold500Typography>
              </InterTextTypography>
            </Stack>
          </OnboardingCardLowRadius>
        </Grid>
      </Grid>

      {userData?.cardAcquired === "yes" && (
        <OnboardingCardLowRadius sx={{ padding: "24px !important" }}>
          <LineDecorator style={{ backgroundColor: theme.primaryColor }} />
          <TitleExtraBoldTypography color={theme.primaryColor} align="center">
            Dados do cartão de Crédito
          </TitleExtraBoldTypography>
          <Stack gap="8px" width="100%">
            <InterTextTypography>
              Cobertura:&nbsp;
              <Bold500Typography>Internacional</Bold500Typography>
            </InterTextTypography>
            <InterTextTypography>
              Dia de vencimento da fatura:&nbsp;
              <Bold500Typography>
                {userData?.cardExpirationDate}
              </Bold500Typography>
            </InterTextTypography>
          </Stack>
        </OnboardingCardLowRadius>
      )}

      <SubTitleInterTypography textAlign="center" mt="40px">
        Após revisar os dados, clique no botão{" "}
        <BoldTypography>“Continuar”</BoldTypography> para ler e assinar o
        formulário.
      </SubTitleInterTypography>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="center"
        gap="24px"
        mt="48px"
      >
        <Button
          color="primary"
          variant="outlined"
          size="large"
          onClick={onBack}
        >
          Corrigir dados
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="large"
          sx={{ paddingInline: "24px" }}
          onClick={handleSaveAndContinue}
        >
          Continuar
        </Button>
      </Stack>
    </ContainerContent>
  );
};

export default ReviewClientData;
